@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Mulish:wght@300;400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,600&display=swap");
@font-face { font-family: 'Futura Cyrillic'; src: url('../../../../public/assets/fonts/FuturaCyrillic-Bold.eot'); src: url('../../../../public/assets/fonts/FuturaCyrillicLight.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/FuturaCyrillicLight.woff2') format('woff2'), url('../../../../public/assets/fonts/FuturaCyrillicLight.woff') format('woff'), url('../../../../public/assets/fonts/FuturaCyrillicLight.ttf') format('truetype'); font-weight: 300; font-style: normal; font-display: swap; }
@font-face { font-family: 'Futura Cyrillic'; src: url('../../../../public/assets/fonts/FuturaCyrillic-Bold.eot'); src: url('../../../../public/assets/fonts/FuturaCyrillic-Bold.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/FuturaCyrillic-Bold.woff2') format('woff2'), url('../../../../public/assets/fonts/FuturaCyrillic-Bold.woff') format('woff'), url('../../../../public/assets/fonts/FuturaCyrillic-Bold.ttf') format('truetype'); font-weight: 700; font-style: normal; font-display: swap; }
@font-face { font-family: 'Futura Cyrillic'; src: url('../../../../public/assets/fonts/FuturaCyrillic-ExtraBold.eot'); src: url('../../../../public/assets/fonts/FuturaCyrillic-ExtraBold.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/FuturaCyrillic-ExtraBold.woff2') format('woff2'), url('../../../../public/assets/fonts/FuturaCyrillic-ExtraBold.woff') format('woff'), url('../../../../public/assets/fonts/FuturaCyrillic-ExtraBold.ttf') format('truetype'); font-weight: 800; font-style: normal; font-display: swap; }
@font-face { font-family: 'Futura Cyrillic'; src: url('../../../../public/assets/fonts/FuturaCyrillicMedium.eot'); src: url('../../../../public/assets/fonts/FuturaCyrillicMedium.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/FuturaCyrillicMedium.woff2') format('woff2'), url('../../../../public/assets/fonts/FuturaCyrillicMedium.woff') format('woff'), url('../../../../public/assets/fonts/FuturaCyrillicMedium.ttf') format('truetype'); font-weight: 500; font-style: normal; font-display: swap; }
@font-face { font-family: 'Futura Cyrillic Book'; src: url('../../../../public/assets/fonts/FuturaCyrillicBook.eot'); src: url('../../../../public/assets/fonts/FuturaCyrillicBook.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/FuturaCyrillicBook.woff2') format('woff2'), url('../../../../public/assets/fonts/FuturaCyrillicBook.woff') format('woff'), url('../../../../public/assets/fonts/FuturaCyrillicBook.ttf') format('truetype'); font-weight: normal; font-style: normal; font-display: swap; }
@font-face { font-family: 'Open Sans'; src: url('../../../../public/assets/fonts/OpenSans-Light.eot'); src: url('../../../../public/assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/OpenSans-Light.woff2') format('woff2'), url('../../../../public/assets/fonts/OpenSans-Light.woff') format('woff'), url('../../../../public/assets/fonts/OpenSans-Light.ttf') format('truetype'); font-weight: 300; font-style: normal; font-display: swap; }
@font-face { font-family: 'Open Sans'; src: url('../../../../public/assets/fonts/OpenSans-Regular.eot'); src: url('../../../../public/assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../../../../public/assets/fonts/OpenSans-Regular.woff2') format('woff2'), url('../../../../public/assets/fonts/OpenSans-Regular.woff') format('woff'), url('../../../../public/assets/fonts/OpenSans-Regular.ttf') format('truetype'); font-weight: normal; font-style: normal; font-display: swap; }
$baseFontRegular: "Open Sans", sans-serif;
$baseFontBold: "Open Sans", sans-serif;
$baseFont3: 'Montserrat';
$darktext: #000000;
// $white: #000000;
$lightbgyellow: #fff4c7;
$baseblue: #284261;
$baseblue1: #4e85fb;
$base-hover: #284261c7;
$bordergrey: #bcbcbc50;
$pendingtext: #e5862f;
$portfoliobg: #f7f9fd;
$bgtablehead:#F6F6F6;
$borderdarkgrey: #6b6b6b;
$bgderkgrey: #f3f3f3;
$grey-707070: #707070;
$border-dcd5d5:#dcd5d5;
$text-80838E: #80838e;
$bg-icon-80838E: rgba(128, 131, 142, 0.1);
$green-098551: #098551;
$tableborders: #00000012;
$bg-light-FBFCFF: #fbfcff;
$bgrewardata: #f8f8f8;
$bgblue-ffffff4a: #ffffff4a;
$bg-light-gray-F3F3F3: #f3f3f3;
$bg-light-blue-E9F5FF: #284261;
$borderColor: #dcd6d6;
$bg-totalearning: #bdcef0;
$bg-totalearning1: #fafcff00;
$border-light-gray-F3F3F3: #f3f3f3; // Dual Theme control mode start
$staticBorder: $bordergrey;
$CommonButton: $baseblue;
$checkboxparagraph: black;
$portfolioHead: #ecfffe;
$btnTextLight: $text-80838E;
$link: #03a89f;
$lightblue-F5F8FF: #284261;
$bgseparator:#585959;
$white: white;
$red: #eb4d5c;
$red-CF304A: #eb4d5c;
// $red-CF304A: #cf304a;
$tabletext: #1b1c1e;
$plateformtext: #616161;
$plateformhead: #0b3d4c;
$green: #53b987;
$tabletext: #1b1c1e;
$tabletext: #1b1c1e;
$blue-F1F5FF: #f1f5ff;
$blue-F3F7FF: #f3f7ff;
$tableborder:#D9D9D9;
$theme-color: #4b719c;
$text-color: #1A2C3F;
$futura: 'Futura Cyrillic' !important;
$OpenSans:  'Open Sans' !important;
$gray-text: #707070;
$menu-bg: #000000;

// Dual Theme control mode start

$themes: (
  lightTheme: (
    "securityBtn": #dfe3ec,
    "darkWhite": #000,
    "darkWhiteReverse": #fff,
  ),
  darkTheme: (
    "securityBtn": #282c34,
    "darkWhite": #fff,
    "darkWhiteReverse": #000,
  ),
);
