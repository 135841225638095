.dropdown-container {
    position: relative;
    width: 200px;
}

.status-dropdown {
    width: 100%;
    padding-left:10px;
    height: 40px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #00f1ff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    appearance: none;
    cursor: pointer;
    outline: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 24 24'%3E%3Cpath fill='%2300f1ff' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 25px;
}

.status-dropdown option {
    font-size: 16px;
    color: #333;
    background-color: #fff;
    padding: 10px;
}

.status-dropdown option:hover {
    background-color: #e0f7fa;
}

.status-dropdown option:checked {
    background-color: #00f1ff;
    color: #fff;
}
