.pageMain .page_title{ margin-top: 10px; }

.contact-card {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    margin:10px 0 0 25px;
}

.contact-header {
    display: flex;
    align-items: center;
    width: 90%;
}

.profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
}

.contact-details {
    flex: 1;
}

.contact-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.contact-label {
    font-weight: bold;
    color: #555;
}

.contact-value {
    color: #333;
}
