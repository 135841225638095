@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";


.pageMain{
  .page_title{
    padding-bottom: 0 !important;
  }
}
.contact-list-card{
  display: flex;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  column-gap: 20px;
  padding-right: 20px;
  align-items: start;
  .contact-card{
    width: 100%;
    max-width: 588px;
    margin-top: 0 !important;
    background-color: #fff !important;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
  .share-documents{
    h3{
      color: $theme-color;
      font-size: 19px;
      font-weight: 500;
      line-height: 32px;
    }
  }
}
.editIcon{
  margin-left: 5px;
  background-color: $theme-color !important;
  color: #fff !important;
  border: none !important;
  font-size: 17px !important;
  svg{
    margin-right: 7px;
  }
}
.delete-icon{
  background-color: #e74c3c !important;
  color: #fff !important;
  border: none !important;
  font-size: 17px !important;
  svg{
    margin-right: 7px;
  }
}
.reset-icon{
  margin-left: 5px;
  background-color: rgba(0, 155, 10, 1) !important;
  color: #fff !important;
  border: none !important;
  font-size: 17px !important;
  svg{
    margin-right: 7px;
  }
}
