@import "../../Assets/Styles/_mixins.scss";
@import "../../Assets/Styles/_vars.scss";

.home {
  width: 100%;
  display: flex;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    height: initial;
  }

  &__inner {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
    @media (max-width: 1199px) {
      width: 100%;
    }

    @media (max-width: 991px) {
      width: 100%;
      max-width: 100%;
    }

    &__mover {
      max-width: 390px;
      width: 100%;
      padding: 24px 40px 20px 24px;
      display: flex;
      flex-direction: column;

      ul {
        list-style: none;
        padding-left: 0;
      }

      border-left: 1px solid $bordergrey;

      @media (max-width: 1366px) {
        max-width: 345px;
        padding: 20px 0;
      }

      @media (max-width: 1199px) {
        width: 100%;
        max-width: 275px;
      }

      @media (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        border-left: none;
      }
    }
  }

  &__prices {
    padding:0;
    >div {
      overflow: hidden;
      justify-content: space-between;
    }


    table {
      margin-top: 0;

      td:first-child {
        padding: 10px 40px 10px 5px;
      }

      th {
        background-color: #e6e6e6 !important;
      }

      th:first-child {
        padding-left: 40px;
      }

      td:last-child {
        text-align: left
      }
    }

    h4 {
      @include fluid-type(320px, 1920px, 14px, 20px);
      color: $theme-color;
      font-weight: 500;
      font-family: $baseFont3;
      font-size: 22px !important;
      padding: 0 29px 13px;
      @include breakpoint(small){
        padding: 0;
      }
    }

    @media (max-width: 1366px) {
      padding: 20px;
    }

    @media (max-width: 991px) {
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0 15px 20px;
      margin-bottom: 20px;
      @include breakpoint(x-small){
        margin-bottom: 0;
      }
    }
  }
}

// .ecoCard {
//   background-color: #f7f9fd;
//   text-align: center;
//   padding: 45px 20px;

//   h4 {
//     @include fluid-type(320px, 1920px, 14px, 20px);
//     font-weight: 600;
//     color: $baseblue;
//     padding: 18px 0 15px 0;
//   }
// }

// .euroCurrency {
//   background-color: #ffffff;

//   .headerMainEx {
//     position: fixed;
//     top: 0;
//     background-color: transparent;

//     .header {
//       max-width: 1364px;
//       width: 100%;
//       margin: 0 auto;

//       .lghtLogo {
//         display: none;
//       }

//       .drkLogo {
//         opacity: unset !important;
//       }

//       .navigationLink li a:hover {
//         color: $baseblue !important;
//       }

//       .menu .item .text {
//         color: #fff !important;

//         &:hover {
//           color: #fff !important;
//         }
//       }

//       .topLeft .r-nav li a:hover {
//         color: $baseblue !important;
//       }

//       .topLeft .loginSec li a:hover {
//         color: $baseblue;
//       }

//       .dropNav .menu .item .text {
//         color: #000 !important;
//       }

//       .dropNav .menu .item {
//         &:hover {
//           .text {
//             color: #fff !important;
//           }
//         }
//       }

//       .dropNav {
//         .text {
//           color: #a5a5ab !important;
//         }
//       }

//       .topLeft .ui.simple.dropdown:hover .text,
//       .topLeft .ui.simple.dropdown:hover .icon {
//         color: $baseblue;
//       }
//     }
//   }

//   .container {
//     max-width: 1364px;
//     width: 100%;
//     margin: auto;
//   }

//   .euroMultiCurrency {
//     background-repeat: no-repeat;
//     background-size: cover;
//     background-image: url("../../Assets/Images/bgHeader.svg");
//     min-height: 900px;
//     background-position: -20%;
//     height: 100%;
//     padding-top: 100px;
//     background-color: #17121d;

//     @media (max-width: 991px) {
//       padding-top: 50px;
//     }

//     h2 {
//       font-weight: 590;
//       @include fluid-type(320px, 1920px, 30px, 54px);
//     }

//     &__Everything {
//       display: flex;
//       justify-content: center;
//       // padding-left: 20px;
//       // padding-right: 20px;
//       align-items: center;

//       @media (max-width: 1399px) {
//         padding: 30px;
//       }

//       @media (max-width: 991px) {
//         flex-direction: column;
//         padding: 45px 15px 0px 15px;
//       }

//       &__left {
//         width: 50%;

//         @media (max-width: 991px) {
//           width: 100%;
//         }

//         h2 {
//           @include fluid-type(320px, 1920px, 30px, 50px);
//           color: #ffffff;
//           margin-bottom: 16px;
//           opacity: 1;

//           @media (max-width: 1199px) {
//             margin-bottom: 10px;
//           }

//           @media (max-width: 991px) {
//             max-width: 100%;
//           }
//         }

//         p {
//           color: #ffffff;
//           opacity: 1;
//           @include fluid-type(320px, 1920px, 16px, 20px);

//           @media (max-width: 991px) {
//             max-width: 100%;
//           }

//           @media (max-width: 767px) {}
//         }

//         a {
//           max-width: 190px;
//           height: 50px;
//           padding: 15px 54.2px;
//           background: $baseblue;
//           border-radius: 30px;
//           opacity: 1;
//           color: #000;
//           font-weight: 600;
//           margin-top: 30px;
//           display: inline-block;

//           @media (max-width: 1199px) {
//             padding: 13px 50px;
//           }
//         }
//       }

//       &__right {
//         width: 50%;

//         @media (max-width: 991px) {
//           width: 100%;
//           display: flex;
//           justify-content: center;
//         }

//         .fundADDImg {
//           width: 100%;

//           @media (max-width: 991px) {
//             max-width: 500px;
//           }

//           @media (max-width: 1199px) {
//             padding-top: 30px;
//           }
//         }
//       }
//     }
//   }

//   .cryptoChain {
//     display: flex;
//     gap: 60px;
//     background: $baseblue 0% 0% no-repeat padding-box;
//     box-shadow: 0px 3px 22px #00000029;
//     border-radius: 20px;
//     opacity: 1;
//     padding: 50px 30px;
//     margin-top: -132px;

//     @media (max-width: 991px) {
//       flex-wrap: wrap;
//       justify-content: center;
//       align-items: center;
//       gap: 20px;
//     }

//     @media (max-width: 767px) {
//       margin-top: -90px;
//     }

//     &__innerCryptoChain {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       gap: 14px;
//       text-align: center;

//       h3 {
//         color: white;
//         opacity: 1;
//         @include fluid-type(320px, 1920px, 16px, 18px);
//         margin: 0;
//       }

//       p {
//         @include fluid-type(320px, 1920px, 12px, 15px);
//         color: white;
//         opacity: 1;
//         margin: 0;
//       }
//     }
//   }

//   .aboutEmperorcoin {
//     display: flex;
//     // gap: 60px;
//     margin: 90px 0;
//     align-items: center;

//     @media (max-width: 991px) {
//       flex-direction: column;
//       gap: 20px;
//       margin: 40px 0;
//     }

//     &__aboutImg {
//       position: relative;
//       display: flex;

//       img {
//         z-index: 9;
//       }

//       .lottieAntimation {
//         position: absolute;
//         top: 0;
//       }
//     }

//     &__aboutContact {
//       h3 {
//         color: $baseblue;
//         text-transform: uppercase;
//         opacity: 0.1;
//         @include fluid-type(320px, 1920px, 50px, 100px);
//         margin: 0;
//       }

//       h2 {
//         color: #000000;
//         text-transform: capitalize;
//         opacity: 1;
//         @include fluid-type(320px, 1920px, 16px, 18px);
//         margin-top: -55px;
//       }

//       h4 {
//         color: $baseblue;
//         opacity: 1;
//         @include fluid-type(320px, 1920px, 22px, 44px);
//         margin: 0;
//       }

//       p {
//         color: #000000;
//         @include fluid-type(320px, 1920px, 14px, 16px);
//         max-width: 682px;
//         width: 100%;
//         padding-top: 20px;
//         margin: 0;
//       }
//     }
//   }

//   .servicesProvide {
//     background-color: #fafafa;
//     padding: 60px 0;

//     &__serviceHeading {
//       text-align: center;
//       padding-bottom: 48px;

//       h2 {
//         color: $baseblue;
//         text-transform: uppercase;
//         opacity: 0.1;
//         @include fluid-type(320px, 1920px, 50px, 100px);
//         margin: 0;
//       }

//       h3 {
//         color: $baseblue;
//         opacity: 1;
//         @include fluid-type(320px, 1920px, 22px, 44px);
//         margin: 0;
//         margin-top: -80px;
//       }

//       p {
//         color: #000000;
//         @include fluid-type(320px, 1920px, 14px, 16px);
//         padding-top: 20px;
//         margin: 0;
//       }
//     }

//     &__globalSec {
//       display: flex;
//       justify-content: center;
//       gap: 149px;

//       @media (max-width: 991px) {
//         gap: 0px;
//       }

//       @media (max-width: 767px) {
//         flex-wrap: wrap;
//         gap: 10px;
//       }
//     }

//     &__globalSecInner {
//       max-width: 407px;
//       width: 100%;
//       height: 100%;
//       // height: 349px;
//       padding: 50px 23px;
//       text-align: center;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       gap: 15px;
//       transition: 0.5s ease-in-out;

//       @media (max-width: 767px) {
//         max-width: 100%;
//         height: auto;
//         gap: 0;
//       }

//       &:hover {
//         box-shadow: 0px 0px 20px #0000001a;
//         border-radius: 20px;
//         opacity: 1;
//       }

//       .imgDiv {
//         background: #f5f4f4;
//         padding: 23px;
//         border-radius: 100%;
//         max-width: 110px;
//         margin: 0 auto;
//       }

//       h4 {
//         color: $baseblue;
//         opacity: 1;
//         @include fluid-type(320px, 1920px, 16px, 20px);
//       }

//       p {
//         color: #000000;
//         @include fluid-type(320px, 1920px, 14px, 15px);
//       }
//     }
//   }
// }

.commmonHeading {
  h2 {
    color: $baseblue;
    text-transform: uppercase;
    opacity: 0.1;
    @include fluid-type(320px, 1920px, 50px, 100px);
    margin: 0;
    font-weight: 700;
  }

  h3 {
    color: $baseblue;
    opacity: 1;
    @include fluid-type(320px, 1920px, 22px, 44px);
    margin: 0;
    margin-top: -80px;
    font-weight: 600;
  }

  p {
    color: #000000;
    @include fluid-type(320px, 1920px, 14px, 16px);
    padding-top: 20px;
    margin: 0;
    width: 100%;
  }
}

.imgMap {
  @media (max-width: 991px) {
    display: none;
  }
}

.scrollHeader {
  .euroCurrency {
    .headerMainEx {
      background-color: #17121d;
    }
  }
}

.heading-blue-44 {
  @include fluid-type(320px, 1920px, 24px, 44px);
}

.modalField {
  margin-left: 10px;
  /* Adds space between lines */
  font-size: 14px;
  /* Adjust font size as needed */
}

.buydata {
  color: $baseblue;
}
.pricesData {
  width: 100%;

  h4 {
    margin-bottom: 20px;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .buydata {
    color: $baseblue;
  }



  table {


    @media (max-width: 767px) {
      thead {
        display: none;
      }
    }

    td {
      color: $darktext;

      canvas {
        max-width: 100px !important;
        height: 40px !important;
      }

      @media (max-width:767px) {

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(6) {
          //display: none;
        }

        &:nth-child(4) {
          padding-right: 0;
        }

        &:first-child {
          padding-left: 0;
        }

      }
    }
  }

  .volume_negative {
    transform: rotate(90deg);
  }
}
