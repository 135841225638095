.profile-form {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.form-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 15px; /* Space between grid items */
    margin-bottom: 12px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-group .error-message {
    color: red;
    font-size: 0.9em;
}

.submit-button {
    background-color: #26415e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #1a2d45;
}

.submit-button:disabled {
    background-color: #a6b8c8;
    color: #fff;
    cursor: not-allowed;
    opacity: 0.6;
}

.checkbox-profile {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 10px !important;
    justify-content: flex-start !important;
}

.checkbox-profile label {
    font-size: 14px !important;
    font-weight: normal !important;
    color: #575757 !important;
    margin-left: 5px !important;
}

.checkbox-profile input[type="checkbox"] {
    width: auto !important;
    margin-right: 5px !important;
}

