@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.pageMain {
  .page_title {
    box-shadow: none !important;
    margin-bottom: 17px !important;
    justify-content: space-between;
    align-content: center;
    color: $theme-color;
    font-size: 20px;
    padding: 28px 40px;
    @include breakpoint(xxlarge) {
      padding: 15px 20px 0 0;
      flex-wrap: wrap;
    }
    @include breakpoint(small) {
      padding: 20px;
      &.d-flex {
        flex-wrap: wrap;
        row-gap: 7px;
      }
    }
    h4{
      @include breakpoint(landscape) {
        padding-left: 0;
        font-size: 18px;
      }
    }
    h4+div{
      @include breakpoint(landscape) {
        margin-top: 9px !important;
      }
    }
  }

  .tableMain {
    table {
      .ant-table-tbody {
        tr {
          td {
            a {
              color: #000;
            }
          }
        }
      }
    }

    .ant-table-cell {
      button.ant-btn {
        padding: 0 20px !important;
        margin-right: 11px !important;

        &.contact-btn {
          border: 1px solid transparent !important;

          &:hover {
            background-color: transparent !important;
            border-color: rgb(185, 161, 67) !important;

            span {
              color: rgb(185, 161, 67) !important;
            }
          }
        }

        &.client-btn {
          border: 1px solid transparent !important;

          &:hover {
            background-color: transparent !important;
            border-color: rgb(76, 175, 80) !important;

            span {
              color: rgb(76, 175, 80) !important;
            }
          }
        }
      }
    }

    @include breakpoint(large) {
      overflow: hidden;
      overflow-y: auto;
      max-height: calc(100vh - 470px);
    }
  }
}

.add-new-agent {
  width: 100% !important;
  max-width: 700px !important;

  &.pdf-view {
    max-width: 60% !important;
  }

  .ant-modal-content {
    border-radius: 10px;
    @include breakpoint(small) {
      max-width: 95%;
      margin: 0 auto;
    }

    .ant-modal-header {
      border-radius: 10px 10px 0 0;

      .ant-modal-title {
        font-weight: 500;
        color: $theme-color;
        font-family: $baseFont3;
        font-size: 26px;
        @include breakpoint(small) {
          font-size: 19px;
        }
      }
    }

    .ant-btn {
      border-radius: 30px;
      min-width: 150px;
      min-height: 49px;
      line-height: 49px;
      padding: 0 10px;
      height: auto;
      text-transform: capitalize;
      @include breakpoint(small){
        min-width: 107px;
        min-height: 41px;
        line-height: 41px;
      }
      span {
        font-size: 19px;
        @include breakpoint(small){
          font-size: 16px;
        }
      }

      &.ant-btn-primary {
        background-color: $theme-color;
        border-color: $theme-color;
      }
      &:hover {
        background-color: $theme-color;
        color: #fff;
        border-color: $theme-color;
      }
    }
  }

  .ant-form-item {
    .ant-form-item-label {
      label {
        font-size: 17px;
        @include  breakpoint(small){
          font-size: 15px;
        }
      }
    }

    .ant-form-item-control-input-content {
      input {
        font-size: 15px;
      }
    }
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      span {
        font-size: 15px;
      }
    }
  }

  .ant-btn {
    &.ant-btn-primary {
      margin-top: 12px;
    }
  }

  &.agent-details {
    .ant-modal-body {
      p {
        font-size: 17px;
        line-height: 30px;
      }
    }
  }

  .ant-btn[type="submit"] {
    & + .ant-btn {
      margin-left: 10px;
    }
  }

  .PhoneInput {
    border: 1px solid #d9d9d9;
    padding: 8px;

    input {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }

  .ant-modal-body{
    form{
      &.ant-form-vertical{
        >div{
          @include breakpoint(small){
            display: flex !important;
            flex-wrap: wrap;
            .ant-form-item{
              width: 100%;
            }
          }
        }
        .ant-form-item-row{
          @include breakpoint(small){
            flex-direction:initial;
          }
        }
      }
    }
  }

}

.add-client {
  display: flex;
  padding: 25px 0;

  button {
    background-color: $theme-color;
    border-color: $theme-color;
    min-width: 143px;
    min-height: 43px;
    color: #fff;
    border-radius: 30px;

    &:hover {
      color: $theme-color;
    }
  }
}

.plansNew {
  margin-top: 20px;
  padding: 0 20px;
 min-height: calc(100vh - 371px);
  .ant-card-head-title {
    font-size: 31px;
    font-weight: 500;
    font-family: $baseFont3;
    @include breakpoint(small) {
      font-size: 27px;
      padding: 10px 0;
    }
  }

  .ant-card-body {
    padding: 0 20px 20px !important;

    p {
      padding: 22px 0;
      font-size: 17px;
      border-bottom: 1px solid #f0f0f0;

      strong {
        width: 100%;
        display: inline-block;
      }
    }

    .ant-btn {
      display: inline-block;
      border: 1px solid #4B719C;
      background-color: transparent;
      color: #4B719C;
      min-width: 80%;
      line-height: 49px;
      padding: 0 10px;
      border-radius: 50px;
      min-height: 49px;
      font-size: 16px;
      margin-top: 28px;
      margin-bottom: 12px;
      &:hover {
        background-color: $theme-color;
        color: #fff;
      }
    }
  }

  .ant-card {
    &.selected-plan {
      background-color: $theme-color;
      border-radius: 20px;
      color: #fff;

      .ant-card-head-title {
        color: #fff;
      }

      .ant-btn {
        background-color: #fff;
        color: $theme-color;
      }

      p {
        border-color: #6182a8;
      }
    }
  }

  @include breakpoint(small) {
    margin-top: 20px;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 329px);
  }
}

.profile-icon {
  position: absolute;
  right: 0;
  padding: 2px;
  background-color: $theme-color;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  line-height: 28px;
}

.client-outer {
  .client-profile {
    padding: 28px 20px;
    background: linear-gradient(to right, #2D4B6D, #172637);
    display: flex;
    align-items: center;

    .client-img {
      max-width: 89px;
      min-width: 89px;
      width: 100%;

      img {
        width: 89px;
        height: 89px;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .client-name {
      padding-left: 16px;
      width: 100%;

      h3 {
        font-size: 22px;
        font-weight: 500;
        color: #fff;
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
      }

      p {
        color: #fff;
        font-size: 16px;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 84%;
      }
    }
  }
}

.client-info {
  background-color: #F5F5F5;
  padding: 21px 20px;
  margin-bottom: 30px;

  .info-text-outer {
    display: flex;

    .info-text {
      width: 100%;

      h4 {
        font-size: 20px;
        color: $theme-color;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }

  .info-edit {
    a {
      background-color: $theme-color;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      display: inline-block;
      text-align: center;
      line-height: 28px;
    }
  }

  .info-delete {
    svg {
      width: 25px;
      height: 25px;
      margin: 0 5px 0 0;
    }
  }

  .client-form {
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 0 3px 14.2px rgba(0 0 0 / 10%);
    margin-top: 21px;
    padding: 0 14px;

    p {
      font-size: 16px;
      line-height: 21px;
      color: #707070;
      display: flex;
      border-bottom: 1px solid #DDDDDD;
      padding: 18px 0;

      &:last-child {
        border-bottom: none;
      }

      strong {
        font-weight: 500;
        width: 100%;
        max-width: 150px;
      }

      span {
        width: 100%;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      }
    }
  }
}

.document-accordion {
  width: 100%;
  border: none;
  background-color: transparent;
  margin-bottom: 67px;

  .ant-collapse-item {
    .ant-collapse-header {
      box-shadow: 0 3px 14.2px rgba(0, 0, 0, 0.1);
      margin-top: 11px;
      padding: 13px 20px;
      border-radius: 9px !important;
      background-color: #fff;

      &[aria-expanded="true"] {

      }
    }

    border: none;

    .ant-collapse-header-text {
      .document-inner {
        display: flex;

        .document-content {
          width: 100%;
          text-align: center;

          span {
            font-weight: 600;
            color: #000;
            font-size: 16px;
          }
        }
      }
    }
  }

  .accordion-content {
    p {
      color: #707070;
      font-size: 16px;
      line-height: 21px;
      border-bottom: 1px solid #e1e1e1;
      padding: 12px 0 12px 0;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .ant-collapse-content {
    border-top: none;
    box-shadow: 0 3px 14.2px rgba(0, 0, 0, 0.1);
    border-radius: 9px !important;
    margin-top: 11px;

    .ant-collapse-content-box {
      @include breakpoint(small) {
        padding: 10px;
      }
    }
  }

  .ant-collapse {
    border: none !important;
  }
}

@media (max-width: 768px) {
  .accordion-content {
    p {
      &.clickable {
        cursor: pointer;
        transition: color 0.3s;
        font-size: 14px;
        padding: 10px;
        word-break: break-word;

        &:hover {
          background-color: #4B719C;
          color: #fff;
          border-radius: 5px;
        }
      }
    }
  }
}

.home-btn {
  background: linear-gradient(to right, #2D4B6D, #172637);
  width: 100%;
  display: inline-block;
  text-align: center;
  padding: 19px 0;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.ant-modal-body {
  .ant-form {
    @include breakpoint(small) {
      > div {
        grid-template-columns: auto !important;

        .ant-form-item {
          margin-bottom: 5px;
        }
      }
      .ant-row {
        flex-wrap: wrap;

        .ant-col {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .ant-space {
        display: flex;
        flex-wrap: wrap;

        .ant-space-item {
          width: 48%;
        }
      }
    }
  }
}
.custom-modal-sign .ant-modal {
  width: 60% !important;
}
.ant-modal {
  width: 90% !important;
  @include breakpoint(small) {
    top: 39px;
  }
}
.freeze-modal .ant-modal {
  width: 40% !important;
}

.assetTable {
  @include breakpoint(small) {
    max-height: calc(100vh - 190px);
  }

  &.myAccount {
    @include breakpoint(small) {
      max-height: 100%;
    }
  }
}

.file-upload-container {
  &.portfolio-upload {
    justify-content: end;
    background: transparent;
    box-shadow: none;
    flex-wrap: wrap;
  }
}

.pdf-upload-modal {
  width: 100% !important;
  max-width: 550px !important;

  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-close {
      background: transparent;
      padding: 0;
      margin: 0;

      svg {
        width: 19px;
        height: 19px;
      }
    }
  }

  .ant-modal-body {
    padding: 50px 0;

    select {
      max-width: 402px;
    }

    .modal-btn{
      button{
        border-radius: 30px;
        height: auto;
        line-height: 49px;
        min-height: 49px;
        min-width: 150px;
        padding: 0 10px;
        border: 1px solid #d9d9d9;
        &.ok-btn{
          background-color: $theme-color;
          border-color: $theme-color;
          color: #fff;
        }
        &.cancel-btn{
          background-color: transparent;
          color: #000;
          &:hover{
            background-color: $theme-color;
            border-color: $theme-color;
            color: #fff;
          }
        }
      }
    }
  }
}

.broker-fee-select {
  .ant-select-selector{
    height: 34px !important;
  }
}

.priceTable{
  table{
    tr{
      td:last-child{
        @include  breakpoint(small){
          text-align: right;
        }
      }
    }
  }
}