@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.search-bar {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    padding: 8px 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    @include breakpoint(landscape){
        max-width: 243px;
    }
    @include breakpoint(small) {
        max-width: 100%;
    }
    input {
        &.search-input {
            padding-left: 0;
            @include breakpoint(landscape){
              font-size: 15px;
            }
        }
    }
}

.search-icon {
    color: #888;
    margin-right: 8px;
    flex-shrink: 0;
}

.search-input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
    color: #333;
}

.search-input::placeholder {
    color: #aaa;
}