@import "../../Assets/Styles/_vars.scss";

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: var(--futura-font) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

a,
li,
ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
}

.container {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
}

.layout-main {
    background-color: transparent;
}

.custom-btn {
    min-width: 161px;
    min-height: 70px;
    line-height: 70px;
    display: inline-flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: #fff;
    color: #000;
    border-radius: 35px;
    font-family: $OpenSans;
    font-size: 20px;
    transition: all 0.6s ease-in-out;
    font-weight: 400;
    column-gap: 14px;
    padding: 0 8px 0 26px;
    box-shadow: 0 4px 4px rgba(0 0 0 / 17%);
    @include breakpoint(small){
        min-height: 58px;
        line-height: 58px;
        font-size: 17px;
    }
    span {
        i {
            background-color: $theme-color;
            width: 54px;
            height: 54px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            transition: all 0.6s ease-in-out;
            margin-left: 15px;
        }

        svg {
            stroke: #fff;
        }

        &.icon-circle {
            background: $theme-color;
            @include square(56px);
            display: inline-flex;
            border-radius: 50px;
            align-items: center;
            justify-content: center;
            @include breakpoint(landscape2) {
                @include square(43px);
            }
            @include breakpoint(large) {
                @include square(39px)
            }
        }
    }

    &:hover {
        background-color: $theme-color;
        color: #fff;

        i {
            background-color: #fff;
        }

        span svg {
            stroke: $theme-color;
        }
    }

    &.theme-button {
        background-color: $theme-color;
        color: #fff !important;
        min-width: 161px;
        min-height: 62px;
        line-height: 62px;
        @include breakpoint(small) {
            min-height: 46px;
            line-height: 46px;
        }
        &:hover {
            background-color: $text-color;
        }

        i {
            background-color: #fff;

            svg {
                stroke: $theme-color;
            }
        }
    }
}

.text-center {
    text-align: center;
}

.p-style {
    color: #000;
    font-size: 32px;
    line-height: 34px;
    font-family: $OpenSans;
    font-weight: 300;
    @include breakpoint(landscape2){
     font-size: 24px;
    }
    @include breakpoint(small){
        font-size: 17px;
    }
}

img {
    max-width: 100%;
}

.ant-pagination{
    &.ant-table-pagination-right{
        padding-right: 15px;
    }
}