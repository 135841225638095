@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./Components/Assets/Styles/_mixins.scss";
@import "./Components/Assets/Styles/_vars.scss";
@import "./Components/Assets/Styles/_global.scss";


body {
  margin: 0;
  box-sizing: border-box;
  font-family: $baseFont3 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.exchange {
    .siteHeader {
      background-color: $darktext;
    }
  }
}

* {
  font-family: $baseFont3 ;

  // .exchange {
  //   * {
  //     font-family: $baseFontRegular !important;
  //   }
  // }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}

#root {
  height: 100%;
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;
}

img {
  max-width: 100%;
}

.darkTheme {
  background-color: #181a20;
}

.lightTheme {
  background-color: #fafafa;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100vh;
}

.ant-layout {
  background: $white !important;
}

.ant-layout-content {
  display: flex;
  flex-direction: column;
  background-color: $bg-light-FBFCFF;
  margin: 0 !important;

  // overflow-y: auto;

  .flexAuto {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 15px;

  @include breakpoint(landscape2) {
    padding: 0 30px;
  }
}

.listing,
.commanBgFull,
.launchpadHis {
  .container {
    width: 100%;
    max-width: 1599px;
    margin: 0 auto;
    padding: 0 15px;
  }
}

// Sidebar table css common_________________________

.ant-table {
  background-color: transparent;
  height: 100%;
  overflow: auto;

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody>tr>td {
    @include fluid-type(320px, 1920px, 12px, 15px);
  }

  .ant-table-thead>tr>th {
    font-weight: 600;
  }
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;

  tr th {
    border-bottom: 0 !important;
    white-space: nowrap;
  }

  @include fluid-type(320px, 1920px, 10px, 14px);
  border-bottom: 0 !important;
  white-space: nowrap;
}

td {
  background-color: transparent;

  border-bottom: 0 !important;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

// Common csss----------------------End

//sidebar css starts here //

.ant-layout-sider {
  border-right: 1px solid;

  &-trigger {
    color: $darktext;
    border: 1px solid;
  }
}

.ant-menu {
  &-item {
    padding-left: 30px !important;
    margin-top: 0 !important;

    &-icon {
      display: flex !important;
      height: 100% !important;
      align-items: center !important;
    }

    &-only-child {
      position: relative;
      padding-left: 80px !important;

      &::before {
        content: "";
        position: absolute;
        left: 60px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 5px;
        border-radius: 100%;
      }
    }
  }

  &-submenu {
    &-title {
      margin-top: 0 !important;
      padding-left: 30px !important;
    }
  }
}

.ant-menu-submenu-popup {
  li {
    padding-left: 30px !important;

    &::before {
      content: unset;
    }
  }
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item {
  padding: 0 calc(50% - 30px) !important;
  @include breakpoint(small){
    padding: 12px 24px 41px !important;
  }
  .ant-menu-item-icon{
    @include breakpoint(small){
      display: inline-block !important;
    }
  }
  .ant-menu-title-content{
    @include breakpoint(small){
      opacity: 1 !important;
      top: -5px;
    position: relative;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

// / Firefox /
// input[type="number"] {
//   -moz-appearance: textfield !important;
// }

//scrollbar {
// ::-webkit-scrollbar-track {
//   border-radius: 0px;
//   // background-color: $scrollbarTrack;
// }

// ::-webkit-scrollbar-thumb {
//   // background-color: $scrollbarThumb;
//   border-radius: 3px;
//   height: 90px;
// }

// .scrollBar:hover::-webkit-scrollbar,
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 6px;
// }

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

table td,
th {
  white-space: nowrap;
}

.timerDesign {
  span {
    background: #fcd535;
    padding: 4px 3px;
    margin: 0px 5px;
    border-radius: 2px;
    font-weight: 500;
  }
}

// Commonly used Css//

.headingCommon {
  @include fluid-type(320px, 1920px, 16px, 24px);
  font-weight: bold;
  color: $darktext;
  line-height: 33px;
}

.otpFields {
  >div {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }

  input {
    border: 1px solid $bordergrey;
    border-radius: 16px;
    height: 60px !important;
    width: 67px !important;
    background-color: #ffff;
    margin: 26px 10px 36px 0px !important;

    @media (max-width: 767px) {
      height: 40px !important;
      width: 45px !important;
      border-radius: 10px;
    }

    @media (max-width: 420px) {
      height: 30px !important;
      width: 35px !important;
      border-radius: 8px;
    }

    &:focus-visible {
      outline: 1px solid $baseblue;
      border: none;
    }
  }
}

.mainLayout {
  min-height: 100vh;
  height: 100%;

  .ant-menu-item {
    .ant-menu-title-content {
      font-size: 18px;
    }
  }

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }

  .ant-layout-content {
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ant-layout-header {
    background-color: #fff;
  }

  .ant-layout-sider {
    background-color: #040000;
    border-right: 1px solid $bordergrey;
    max-width: 252px !important;
    min-width: 252px !important;
    width: 252px !important;
    padding-top: 24px;
    &.sideBar{
      @include breakpoint(small){
        display: none;
      }
    }
  }

  .ant-layout-sider-children {
    .ant-menu {
      .ant-menu-item-only-child {
        height: auto !important;

        &:hover {
          background-color: white !important;

          .ant-menu-title-content {
            color: initial !important;
          }
        }

        &::before {
          display: none !important;
        }

        .ant-menu-title-content {
          line-height: normal;
          color: #e2e5ec;
        }

        .tradingInner {
          .innerContact {
            ul {
              list-style-type: disc;
              padding-left: 38px;

              li {
                @include fluid-type(320px, 1920px, 12px, 14px);
                color: $text-80838E;
                margin-top: 10px;

                &:hover {
                  color: $link;
                }
              }

              .active {
                color: $link;
              }
            }
          }
        }
      }
    }
    label{
      img{
        @include breakpoint(landscape){
          width: 115px !important;
          margin-left: 24px;
        }
      }
    }
  }

  .logo {
    cursor: pointer;
    height: 76px;
    padding: 25px 27px 0px 35px;
    position: sticky;
    top: 0;
    background-color: black;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    // border-bottom: 1px solid $bordergrey;

    @media (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      img {
        width: 60px;
      }
    }
  }

  .ant-layout-header {
    height: 86px !important;
    border-bottom: 1px solid $bordergrey;
  }

  .ant-layout-sider-collapsed {
    max-width: 120px !important;
    min-width: 120px !important;
    width: 120px !important;

    @media (max-width: 767px) {
      max-width: 80px !important;
      min-width: 80px !important;
      width: 80px !important;
    }

    .ant-menu {
      padding-right: 0;
    }
  }

  .ant-layout-sider-children {
    overflow-y: auto;
  }

  .ant-menu {
    padding-right: 0;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    gap: 4px;

    svg {
      min-width: 20px;
      height: 20px;
    }

    &.collapsed {
      transition: 0.3s all;

      .ant-menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
          justify-content: center;
        }

        .ant-menu-title-content {
          display: none;
        }
      }
    }

    .ant-menu-item-active {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        z-index: 1;
        background-color: $baseblue;
        border-radius: 0 4px 4px 0;
        display: none;
      }
    }

    .ant-menu-item-selected {
      background-color: $theme-color !important;
      border-radius: 0;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        z-index: 1;
        background-color: $baseblue;
        border-radius: 0 4px 4px 0;
        display: none;
      }

      .ant-menu-title-content {
        color: #fff;
      }
    }

    .ant-menu-item {
      border-radius: 0;
      font-weight: 600;
      @include fluid-type(320px, 1920px, 12px, 16px);
      padding-left: 17px !important;
      height: 55px;
      // border-left: 4px solid transparent;
      margin-bottom: 0px !important;
      // gap: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;

        span {
          display: none;
        }
      }

      &:hover {
        background-color: $theme-color !important;

        .ant-menu-title-content {
          color: #fff;
        }
      }

      .ant-menu-title-content {
        // color: $darktext;
      }

      svg {
        color: #fff;

        path {
          // fill: #fff;
          // stroke: #fff;
        }
      }
    }
  }

  .fa {
    content: "\f105";
    color: $borderdarkgrey;
  }
}

.ant-card-body {
  padding: 0 !important;
}

.commitModalstyle {
  .ant-modal-content {
    border-radius: 20px;
    max-width: 574;
    width: 100%;
    margin: auto;
  }

  h3 {
    text-align: center;
    @include fluid-type(320px, 1920px, 16px, 20px);
    color: $darktext;
  }

  &_content {
    background: rgba(123, 123, 123, 0.06);
    padding: 24px;
    border-radius: 24px;
    margin: 45px 0 20px 0;

    @media (max-width: 575px) {
      padding: 15px;
    }

    ul {
      font-family: $baseFont3 ;
      padding-left: 0;
      margin-bottom: 0;

      li {
        color: $text-80838E;
        display: flex;
        gap: 5px;
        font-weight: 500;
        justify-content: space-between;
        list-style: none;
        padding-bottom: 15px;
        @include fluid-type(320px, 1920px, 14px, 16px);

        &:last-child {
          padding-bottom: 0;
        }

        span {
          color: $darktext;
        }
      }
    }
  }
}

.customModalStyle {
  .ant-modal-content {
    border-radius: 20px;
    max-width: 416px;
    font-weight: 500;
    width: 100%;
    margin: auto;
  }

  .ant-modal-header {
    background-color: transparent;
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 20px;
  }
}

.copydataIcon {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: #1258ee;
  color: #fff;
  box-shadow: inset 0px 0px 24px white;
  border-radius: 10px;
  pointer-events: all;
  transition: all 0.1s linear;
  opacity: 1;

  &:empty {
    opacity: 0;
    pointer-events: none;
  }
}

// btngroups

.btngroup {
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .ant-btn.btn_custom {
      height: 40px !important;
      width: 100%;
      padding: 10px 40px;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 600;

      span {
        @include fluid-type(320px, 1920px, 14px, 16px);
      }
    }

    .btnCustom.ant-btn.lytgaryBtn {
      @include fluid-type(320px, 1920px, 14px, 16px);
      height: 40px;
    }
  }
}

.headerbtns {
  .ant-btn.btn_custom {
    padding: 10px !important;
  }
}

.icons {
  &.padding0 {
    button {
      padding: 0 !important;
    }
  }

  .btnCustom.ant-btn.lytgaryBtn {
    height: 40px;
    width: 40px;

    @media (max-width: 767px) {
      height: 30px;
      width: 30px;
    }
  }

  .ant-modal-close {
    top: 0;
    right: 0;
  }

  &.TableModalStyle {
    .ant-modal-header {
      border-bottom: none;

      .blue {
        color: $baseblue;
      }
    }

    .ant-modal-close {
      background-color: $white;
      border-radius: 50%;
      top: -44px;
      right: -19px;

      .ant-modal-close-x {
        width: 30px;
        height: 30px;
        line-height: 2;
      }
    }
  }
}

// prices

.priceTable {
  cursor: pointer;

  table {
    // border: 1px solid $bordergrey;
    th{
      @include breakpoint(small){
        font-size: 16px !important;
      }
    }
    tbody {

      tr {
        td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.0705882353) !important;
          @include breakpoint(small){
            font-size: 16px !important;
            button{
              min-width: 118px !important;
              width: auto !important;
              padding: 0 10px !important;
              font-size: 16px !important;
              min-height: 40px !important;
              line-height: 40px !important;
            }
          }
          @include breakpoint(landscape){
            font-size: 16px !important;
           padding: 7px 6px;
          }
        }

        &:hover {
          transition: 1s all;
          box-shadow: none;
        }
      }
    }
  }

  .downarrows {
    img {
      margin-right: 4px;
    }
  }

  .ant-table-cell{
    .ant-btn{
      min-width: 150px;
      width: auto;
      min-height: 47px;
      line-height: 47px;
      span{
        font-size: 19px;
      }
      @include breakpoint(landscape){
        min-width: 120px;
        min-height: 39px;
        line-height: 39px;
        span{
          font-size: 15px;
        }
      }
    }
  }

}
@media screen and (max-width: 768px) {
  .priceTable .ant-table-thead {
    display: none; /* Hide the table header */
  }
}

.bitData {
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-table-thead>tr>th {
    position: relative;
    text-align: left;
    background: #fff !important;
    color: rgba(0, 0, 0, 0.85);
  }

  p {
    margin-bottom: 2px;
    color: $borderdarkgrey;
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 500;
  }

  .coins {
    @include fluid-type(320px, 1920px, 12px, 15px);
    color: $tabletext;
    font-weight: 400;
  }
}

// topmover
.tribe {
  &.triveTxtCenter {
    display: flex;
    align-items: center;
  }

  p {
    color: $borderdarkgrey;
    @include fluid-type(320px, 1920px, 12px, 14px);
  }

  .topmovericon {
    font-weight: 600;
    color: $tabletext;
    @include fluid-type(320px, 1920px, 12px, 15px);
  }
}

.pricedatamover {
  text-align: right;

  p {
    img {
      margin-right: 4px;
    }
  }

  p {
    color: $darktext;
    @include fluid-type(320px, 1920px, 12px, 15px);
  }
}

// portfoliotable
.portfolioTable {
  width: 100%;

  &.cursorInitial {
    .ant-table-tbody>tr {
      cursor: initial;
    }
  }

  .bitData {
    display: flex;
    align-items: center;
    gap: 6px;
    // .bitdatainner{
    //   padding-right: 15px;
    // }
  }

  .ant-table-content {
    height: calc(100vh - 383px);
    overflow: auto !important;

    @include breakpoint(small) {
      height: auto;
      overflow: hidden;
      overflow-x: auto;

      .ant-table-thead {
        th {
          &.ant-table-cell {
            display: table-cell;
          }
        }
      }

      .ant-table-tbody {
        td {
          &.ant-table-cell {
            display: table-cell;
          }
        }
      }
    }
  }

  .ant-table-thead>tr {
    >th {
      background-color: #e6e6e6 !important;

      &:first-child {
        padding-left: 40px;
        padding-right: 40px;

        @media (max-width: 991px) {
          padding-left: 20px;
          padding-right: 30px;
        }
      }
    }
  }

  .ant-table-tbody>tr:last-child td {
    border-bottom: none !important;
  }

  .ant-table-tbody>tr {
    cursor: pointer;

    >td {
      border-bottom: 1px solid $tableborders !important;
      color: $borderdarkgrey;

      &:first-child {
        padding-left: 40px;
        padding-right: 40px;

        @media (max-width: 991px) {
          padding-left: 20px;
          padding-right: 30px;
        }
      }
    }
    td{
      @include breakpoint(landscape){
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px !important;
      }
    }
  }

  h6 {
    font-size: 14px;
  }

  .mngitem {
    display: flex;
    align-items: center;
    gap: 5px;

    input[type="checkbox"] {
      accent-color: #3a938e;
    }
  }

  .mngitems {
    cursor: pointer;
  }
}

.rewardsData {
  border-bottom: 1px solid $tableborders !important;
  margin-bottom: 0 !important;
  padding: 17px 25px;

  @media (max-width: 1400px) {
    padding: 17px 10px;
  }

  @media (max-width: 991px) {
    &:last-child {
      border-bottom: none !important;
    }
  }

  .tribeData {
    @media (max-width: 480px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .pricedatamover {
    @media (max-width: 480px) {
      text-align: center;
    }
  }
}

/*card...........*/
div[class$="card"] {
  border: 1px solid $bgderkgrey;
  border-radius: 20px;
  box-shadow: 0px 0px 12px #00000012;

  li {
    cursor: pointer;
  }
}

/*===========header=============*/
// .responsiveSidebar {
//   display: none;
// }
html,
body {
  min-height: 100vh !important;
  height: initial !important;
}

.drawer-open {
  overflow: hidden;
}

.drawerLanding {
  // overscroll-behavior: contain;
  // -webkit-overflow-scrolling: touch;
  // touch-action: none;

  .ant-drawer-content-wrapper {
    max-width: 378px;
    width: 100% !important;
    @media (max-width: 991px) {
      max-width: 100%;
      height: auto !important;
      .btn_custom {
        margin: 10px 0;
      }
    }
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;

    .ant-drawer-close {
      margin: 0;
    }
  }
}

.ant-drawer-title {
  h2 {
    color: $baseblue !important;
  }
}

.ant-drawer-wrapper-body {
  a {
    display: block;
    color: $darktext;
    padding: 10px 0;

    @media (max-width: 991px) {
      color: $white;
    }

    &:hover {
      color: $baseblue;
    }
  }
}

.siteHeader {
  transition: all 0.3s linear;

  &.bg-white {
    background-color: $darktext;
  }
}

.ant-drawer-header,
.ant-drawer-wrapper-body {
  background-color: $white;

  @media (max-width: 991px) {
    background-color: $tabletext;
  }

  button.ant-btn {
    background-color: $baseblue !important;
    color: $white !important;

    &:hover {
      opacity: 0.8;
    }
  }
}

.ant-drawer-header {
  background-color: transparent !important;
  border-bottom: 1px solid $grey-707070 !important;

  .anticon {
    color: $darktext;

    @media (max-width: 991px) {
      color: $white;
      transition: 0.3s all;

      &:hover {
        color: $baseblue;
      }
    }
  }
}

/*======================comman-text===============*/

h3[class^="text-black-36"] {
  color: $darktext;
  font-weight: 600;
  @include fluid-type(320px, 1920px, 20px, 36px);
  line-height: 50px;
  font-family: Open Sans;
}

h4[class^="text-black-20"] {
  padding: 28px 40px;
  color: $darktext;
  @include fluid-type(320px, 1920px, 14px, 20px);
  font-weight: 600;

  @media (max-width: 991px) {
    padding: 22px 20px;
  }
}

h4,
h2[class$="blue"] {
  color: $baseblue;
}

h2[class^="logo"] {
  font-weight: 800;
  @include fluid-type(320px, 1920px, 18px, 36px);
}

h2[class$="white"] {
  color: white;
}

/*text........*/
p[class^="text-grey-14"] {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 12px, 14px);

  a {
    color: $baseblue;
    text-decoration: underline;
  }
}

p[class$="twelve"] {
  @include fluid-type(320px, 1920px, 10px, 12px);
}

p[class^="grey"] {
  color: $text-80838E;

  @media (max-width: 575px) {
    word-break: break-word;
  }
}

h6[class^="black14"] {
  @include fluid-type(320px, 1920px, 12px, 14px);
  color: $darktext;

  span {
    color: $darktext;
  }
}

div[class$="card"] {
  box-shadow: 0px 0px 12px #00000012;
  border-radius: 20px;

  // height: 100%;
  li {
    cursor: pointer;
  }

  .borderReward {
    border-bottom: 1px solid gray !important;
  }
}

div[class^="outer"] {
  margin: 30px 40px 30px 50px;
  box-shadow: 0px 0px 12px #00000012;
}

.select-leftLabel {
  .SelectOuter {
    display: flex;
    align-items: center;

    .label {
      width: 120px;
      color: $text-80838E;
    }

    .customSelect.ant-select {
      width: 100%;
      max-width: 154px;
      height: 40px;
      margin-bottom: 0;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 21px;
    transition: all 0.3s;
  }
}

a.ant-btn {
  padding: 0 !important;
}

/*=============================tabs-design======================*/
.ant-tabs,
.ant-tabs-top {
  .ant-tabs-nav-more {
    @media (min-width: 991px) {
      display: none !important;
    }
  }

  &.blueTabs {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          // width: 100%;

          @media (max-width: 991px) {
            width: calc(100% - 10px);
          }

          @media (max-width: 767px) {
            width: unset;
          }

          .ant-tabs-tab {
            color: $text-80838E;
            padding: 22px 20px 16px 20px;
            font-weight: 400;
            flex-basis: 33.3%;
            @include fluid-type(320px, 1920px, 12px, 16px);
            margin-left: 0;
            justify-content: center;

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $baseblue;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: $baseblue;
          }
        }
      }
    }
  }

  &.decorationNone {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {

          // width: 100%
          .ant-tabs-tab {
            color: $text-80838E;
            padding: 30px 30px;
            font-weight: 400;
            flex-basis: 33.3%;
            padding-right: 0px;
            @include fluid-type(320px, 1920px, 12px, 16px);

            @media (max-width: 585px) {
              padding: 30px 12px;
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $baseblue;
              }
            }
          }

          .ant-tabs-ink-bar {
            background: transparent;
          }
        }
      }
    }

    // .textDecoration {
    //   .blueTabs {
    //     .ant-tabs-nav {
    //       .ant-tabs-nav-wrap {
    //         .ant-tabs-nav-list {
    //           .ant-tabs-tab {
    //             color: $text-80838E;
    //             padding: 22px 40px 16px 40px;
    //             font-weight: 400;
    //             flex-basis: 33.3%;
    //             @include fluid-type(320px, 1920px, 12px, 16px);
    //           }

    //           .ant-tabs-ink-bar {
    //             background: $baseblue;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  &.transactionshistoryTab {
    .ant-tabs-nav-wrap {
      // @media (max-width:767px) {
      //   overflow-y: hidden;
      //   overflow-x: auto !important;
      //   padding-bottom: 6px;
      // }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 18px 30px !important;
          max-width: 175px;
          @include fluid-type(320px, 1920px, 12px, 16px);

          @media (max-width: 767px) {
            padding: 15px !important;
          }

          &-active {
            border-bottom: 2px solid #284261;
          }
        }
      }
    }
  }

  & .ant-tabs-nav {
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          color: $text-80838E;
          font-weight: 500;
          @include fluid-type(320px, 1920px, 14px, 20px);
          padding: 16px 50px;

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $baseblue;
            }
          }

          @include breakpoint(laptopScreen) {
            padding: 5px 50px;
          }
        }

        .ant-tabs-ink-bar {
          background: $baseblue;
        }
      }
    }
  }
}

.remove-margin {
  .ant-divider-horizontal {
    margin: 0;
  }
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 !important;

  &:first-child {
    margin-left: 40px;
  }
}

.ant-tabs-top>.ant-tabs-nav {
  margin: 0 !important;
}

.line-none {
  &.ant-tabs-top>.ant-tabs-nav {
    &::before {
      border-bottom: none;
    }
  }
}

.portTab {
  width: 100%;

  &.blueTabs {
    .ant-tabs-tab-btn {
      @include fluid-type(320px, 1920px, 14px, 20px);
      font-weight: 500;
    }
  }

  .ant-tabs-nav::before {
    border-bottom: 0 !important;
  }

  .ant-tabs-nav {
    background-color: $bgderkgrey;
    border-radius: 19px;
    padding-right: 37px;
    padding-left: 20px;
  }

  // max-width: 1123px;
}

//=====radio====//
input[type="radio"] {
  // accent-color: $baseblue;
  width: 17px;
  height: 17px;
}

.radiobtn {
  margin-bottom: 40px !important;

  .ant-radio-checked .ant-radio-inner {
    border-color: $baseblue !important;

    &::after {
      background-color: $baseblue;
    }
  }

  .ant-radio-wrapper {
    margin-right: 55px !important;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $baseblue !important;
  }
}

.buySellTab {
  .ant-tabs-nav-list {
    width: 100%;
  }
}

/*=======================buy-sell-conver===================*/
.inputSec {
  sup {
    font-size: 24px;
    margin: 28px 0;
    color: $baseblue;
    top: -6px;

    @media (max-width: 1199px) {
      top: -3px;
    }

    @media (max-width: 585px) {
      font-size: 19px;
      top: -2px;
    }
  }

  .ant-input {
    border: none;
    width: 135px;
    @include fluid-type(320px, 1920px, 20px, 35px);
    font-weight: 600;
    color: $baseblue;
    height: 30px;
    padding: 0px 5px !important;

    &:focus {
      outline: none;
      box-shadow: unset;
    }
  }
}

.btcvalues {
  >div {
    padding: 20px 24px;
    display: flex;
    gap: 16px;

    @media (max-width: 1780px) {
      gap: 7px;
      padding: 16px;
    }

    @media (max-width: 1660px) {
      justify-content: center;
      gap: 15px;
    }

    button {
      height: 40px !important;
      max-width: 124px;
      width: 100%;

      @media (max-width: 1600px) {
        max-width: 100px;
      }

      @media (max-width: 575px) {
        height: 35px !important;
        max-width: 100px;
      }

      span {
        @include fluid-type(320px, 1920px, 13px, 16px);
        font-family: $baseFontRegular;
        font-weight: 500;

        &:first-child {
          margin-right: 7px;
        }
      }
    }
  }
}

.buySellTabs {
  border-radius: 32px;
  height: 35px;
  white-space: nowrap;
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    height: 32px;
  }

  &__btns {
    border-radius: 32px;
    border: 0;
    max-width: 142px;
    width: 100%;

    @media (max-width: 1366px) {
      max-width: 100%;
    }

    &:first-child {
      border-radius: 32px 0 0 32px;
    }

    &:last-child {
      border-radius: 0 32px 32px 0;
    }

    background-color: $bgderkgrey;
    @include fluid-type(320px, 1920px, 14px, 16px);
    color: $darktext;
    font-weight: 500;
    padding: 6px 33px;
    height: 40px;

    @media (max-width: 767px) {
      padding: 5px 20px;
    }

    @media (max-width: 575px) {
      height: 35px;
    }

    cursor: pointer;

    &--active {
      border-radius: auto 0px 0px auto;
      background-color: $green;
      color: $white;
    }
  }
}

.futureTabWallet {
  padding-top: 0 !important;
}

.wallettabs {
  padding-top: 24px;

  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-bottom>.ant-tabs-nav::before,
  .ant-tabs-bottom>div>.ant-tabs-nav::before,
  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border-bottom: 0px;
  }

  .ant-tabs {
    .ant-tabs-tab {
      padding: 0px 10px !important;
      display: block;

      .ant-tabs-tab-btn {
        padding-bottom: 6px;
        text-align: center;
        @include fluid-type(320px, 1920px, 12px, 14px);
        font-family: $baseFontRegular;
      }
    }
  }
}

.grey-bg {
  background-color: $bg-light-gray-F3F3F3;
  color: $darktext;
  text-align: center;
  padding: 14px 33px;
  border-radius: 25px;
  margin: 0 auto 32px auto;
  display: block;
  max-width: 150px;

  &:hover {
    color: $baseblue;
  }
}

.convertIcon {
  li {
    &:last-child {
      &:after {
        content: url("./Components/Assets/Images/Exchange.svg");
        width: 32px;
        height: 32px;
        position: absolute;
        left: 13px;
        top: -15px;
        background-color: $bg-light-gray-F3F3F3;
        text-align: center;
        padding: 8px;
        border-radius: 50%;
      }
    }
  }
}

.convertIconSpan {
  content: url("./Components/Assets/Images/Exchange.svg");
  width: 32px !important;
  height: 32px;
  position: absolute;
  left: 13px;
  top: -17px;
  background-color: $bg-light-gray-F3F3F3;
  text-align: center;
  padding: 8px;
  border-radius: 50%;
}

.transferIcon {
  transform: rotate(90deg);
}

.exchagetitle {
  padding: 5.5px 21px;
  background-color: $bgderkgrey;
  border-bottom: 1px solid $bordergrey;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0px;
    @include fluid-type(320px, 1920px, 12px, 14px);
    font-weight: 600;
    font-family: $baseFont3;
  }
}

.listheading__header {
  ul {
    li {
      font-family: $baseFont3;
    }
  }
}

.orange {
  color: $red-CF304A;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.green {
  color: $green;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.grey {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.btcvalues {
  button {
    height: 40px !important;

    @media (max-width: 575px) {
      height: 35px !important;
    }
  }
}

.grey {
  color: $text-80838E;
  @include fluid-type(320px, 1920px, 10px, 14px);
}

.green {
  color: $green-098551;
}

.monstrateFont {
  font-family: $baseFont3;
}

.depositeWithdraw[disabled] {
  color: #fff !important;
}

.tradeHistory {
  // margin-bottom: 22px;

  .ant-pagination {
    display: none;
  }

  td {
    color: #eb4d5c !important;
  }

  .ant-table-content {
    overflow: auto;
    height: 174px;

    @media (max-width: 1366px) {
      height: 335px;
    }

    @media (max-width: 767px) {
      height: calc(100vh - 270px);
    }
  }

  .ant-table-wrapper {
    .ant-table {
      .ant-table-thead {
        margin-bottom: 10px;
        position: sticky;
        top: 0;

        .ant-table-cell {
          font-weight: 500;
          padding: 3px 21px !important;
          border-bottom: 1px solid $bordergrey !important;
          @include fluid-type(320px, 1920px, 10px, 12px);
          font-family: $baseFontRegular;

          &:last-child {
            text-align: right;

            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-left: 5px !important;
            }
          }

          &:nth-child(2) {
            text-align: center !important;
            font-family: $baseFont3;

            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }
          }

          &:nth-child(1) {
            @media (max-width: 1700px) and (min-width: 1366px) {
              padding-right: 5px !important;
            }
          }

          @media (max-width: 1780px) {
            padding: 10px 16px !important;
          }
        }
      }

      .ant-table-tbody {
        max-height: 188px;

        .ant-table-row {
          // &:first-child td {
          //   padding-top: 14px !important;
          // }

          td {
            @include fluid-type(320px, 1920px, 8px, 11px);
            padding: 0px 22px;
            font-family: $baseFontRegular;

            @media (max-width: 1780px) {
              padding: 0px 16px !important;
            }

            &:nth-child(1) {

              // padding: 0px 0px 0px 22px;
              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-right: 5px !important;
              }
            }

            &:nth-child(2) {
              text-align: center !important;

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-left: 0 !important;
                padding-right: 0 !important;
              }
            }

            &:nth-child(3) {
              // padding: 0px 22px 0px 22px;
              text-align: right;

              @media (max-width: 1700px) and (min-width: 1366px) {
                padding-left: 5px !important;
              }
            }
          }
        }
      }

      .ant-table-pagination {
        display: none !important;
      }
    }
  }
}

// assetTable
.pageMain {
  max-width: 100%;
  @include breakpoint(small){
    padding-bottom: 70px;
  }
}

.assetTable {
  box-shadow: 0px 0px 12px $tableborders;
  border-radius: 20px;
  max-width: 96.3%;
  width: 100%;
  max-width: auto;
  overflow: hidden;
  margin-left: 30px;

  @include breakpoint(large) {
    max-width: 85%;
  }
  @include breakpoint(small){
    max-width: 92%;
    margin-left: 15px;
  }
  &.myAccount {
    @include breakpoint(large) {
      max-width: 100%;
    }
  }

  .pageMain {
    max-width: 100%;
  }

  .ant-table-thead>tr>th {
    background-color: $portfolioHead;
    font-weight: 600;

    &:first-child {
      padding-left: 40px;

      @media (max-width: 991px) {
        padding-left: 20px;
      }
    }
  }

  .ant-table-tbody>tr>td {
    border-bottom: 1px solid $tableborders !important;
    color: $darktext;
    @include fluid-type(320px, 1920px, 12px, 15px);

    &:first-child {
      padding-left: 40px;

      @media (max-width: 991px) {
        padding-left: 20px;
      }

      @media (max-width: 480px) {
        padding-left: 15px;
      }
    }
  }
}

//Drawer css//
.drawerStyle {
  .btngroup>div {
    flex-direction: column;
  }

  .btngroup {
    max-width: 180px;
    margin: auto;
  }
}

.mb24 {
  margin-bottom: 24px;
}

.mb50 {
  margin-bottom: 50px;
}

/*=============40btn===========*/
button[class^="btn40"] {
  height: 40px;
  border-radius: 25px;
  width: 120px;
  border-color: transparent;
}

.tb-grey-btn {
  color: #000;

  &:hover {
    background-color: $baseblue;
    color: $white;
  }
}

// depositModal
.depositModals {
  display: flex;
  justify-content: space-between;
  padding: 4px 20px;
  width: 100%;
  margin-bottom: 36px;

  &:hover {
    background-color: $bg-light-gray-F3F3F3;
    border-radius: 14px;
  }

  .bitassetData {
    display: flex;
    gap: 6px;

    p {
      margin-bottom: 0;
      @include fluid-type(320px, 1920px, 12px, 16px);
    }

    :first-child {
      color: $darktext;
    }

    :nth-child(2) {
      @include fluid-type(320px, 1920px, 12px, 14px);
      color: $text-80838E;
    }
  }
}

.setting {
  text-align: center;

  .ant-dropdown-menu {
    padding-top: 25px;
    padding-bottom: 25px;
    box-shadow: 0px 6px 10px #00000029;
    border-radius: 10px;
    max-width: 256px;

    .ant-dropdown-menu-item {
      padding-left: 20px;
      padding-right: 20px;
      color: #000;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        &:hover {
          background-color: transparent;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        .ant-dropdown-menu-title-content {
          text-align: left;

          button {
            background-color: transparent;
            border: 1px solid $bordergrey;
            border-radius: 18px;
            padding: 9px 18px;
            color: $text-80838E;
            margin-bottom: 16px;
          }

          a,
          span {
            word-break: break-all;
            font-size: 17px;
            font-family: $futura;
            padding: 10px 0;

            &.ant-avatar-string {
              padding: 0;
            }
          }
        }
      }

      span {
        a {
          font-size: 17px;
          font-family: $futura;
          padding: 10px 0;
        }
      }

      .ant-dropdown-menu-title-content {
        .ant-avatar {
          margin: 0 0 10px 0;

          .ant-avatar-string {
            top: 50%;
            transform: translate(-50%, -50%) !important;
          }
        }
      }
    }
  }
}

.submitted,
.accepted,
.processing,
.pending {
  color: #ffb011;
}

.collected,
.completed,
.deposited,
.succeed,
.signup,
.Deposit,
.trade,
.confirming,
.sucess,
.receive {
  color: #098551;
}

.cancelled,
.rejected,
.failed,
.danger {
  color: #cf0038;
}

.cursorInitial {
  cursor: initial;
}

.headerModals {
  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    padding: 0;
  }
}

// <<<<<<<<<<<<<<<===========footer Exchange=====>>>>>>>>
.exchangeFooter {
  height: 45px;
  border: 1px solid $bordergrey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0px;

  p {
    margin: 0px;
    text-align: center;
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: $grey-707070;
  }
}

.sendRecived {
  .ant-tabs-content {
    position: inherit;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    max-width: 200px;
    width: 100%;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    display: block;
  }

  .ant-tabs-tab-btn {
    text-align: center;
    width: 100%;
  }
}

.tabsDrawer {
  margin-top: 10px;

  .ant-tabs-tab {
    padding: 0 !important;
    margin-left: 0px !important;
    border-radius: 25px;
    background-color: $bgderkgrey;

    p {
      padding: 3px 14px;
    }

    .ant-tabs-tab-btn {
      font-size: 12px;
    }
  }

  .ant-tabs-content-holder {
    max-height: 550px;
    overflow: scroll;
    height: 700px;
  }

  .ant-tabs-nav-list {
    gap: 6px !important;
  }

  .ant-tabs-nav {
    padding-bottom: 10px;

    &::before {
      border: 0px !important;
    }
  }

  .ant-tabs-tab-active {
    background-color: $baseblue;
    border-bottom: 0px;

    .ant-tabs-tab-btn {
      color: $white !important;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
}

.marketcomp {
  margin: 14px 0px;
  border-radius: 37px;
  background-color: $bgderkgrey;
  padding: 10px 20px;
  border-radius: 37px;
  background-color: $bgderkgrey;

  @media (max-width: 575px) {
    padding: 8px 10px;
    border-radius: 20px;
  }

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    li {
      white-space: nowrap;
      margin-left: 5px;
      margin-right: 5px;

      @media (max-width: 575px) {
        margin-left: 0px;
        margin-right: 0px;
      }

      &:first-child {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      canvas {
        width: 100px !important;
        display: flex;

        @media (max-width: 575px) {
          width: 70px !important;
        }
      }

      h3 {
        @include fluid-type(320px, 1920px, 10px, 14px);
        font-weight: 600;
      }

      p {
        @include fluid-type(320px, 1920px, 10px, 14px);
        color: $borderdarkgrey;
      }
    }
  }
}

// .......................stateSelect

.stateSelect {
  .SelectOuter {
    display: flex;
    align-items: center;
    height: 40px;

    label {
      margin-right: 10px;
      padding-bottom: 0;
    }

    .select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 19px;
    }

    .customSelect.ant-select {
      height: 40px;
      width: 144px;
      margin-bottom: 0;
      background-color: $bg-light-gray-F3F3F3;
      border: none;
    }

    .ant-select-selector {
      height: 40px;

      .ant-select-selection-placeholder {
        display: inline-block;
        width: 144px;
      }
    }
  }
}

// .....................search

.search {
  position: relative;
  height: 40px;

  .ant-input.input_custum {
    height: 40px;
    padding-left: 50px;
    width: 144px;
    background-color: $bg-light-gray-F3F3F3;
    border: none;
  }

  &::after {
    content: url("./Components/Assets/Images/search.svg");
    position: absolute;
    top: 21px;
    left: 18px;
  }
}

// ...........setting

.verifyBtn {
  background-color: $baseblue;
  color: $white;
  border: none;
  border-radius: 30px;
  padding: 9px 20px;
  min-width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;

  img {
    display: none;
  }

  &.active {
    background-color: $border-light-gray-F3F3F3;
    color: $baseblue;
    border: 1px solid $bordergrey;

    img {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

// loginPage tabs
.loginTab {
  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 16px, 18px);
  }

  .ant-tabs-tab {
    padding: 10px 40px !important;

    @media (max-width: 767px) {
      padding: 10px 30px !important;
    }
  }

  .ant-tabs-nav {
    margin: 25px 0px !important;
  }

  .ant-tabs-top {
    margin-bottom: 10px;
  }
}

.input_righttext {
  margin-bottom: 15px;

  .input_imgcontent {
    p {
      color: $baseblue;
      font-weight: 600;
      padding: 0 !important;
      position: static !important;
    }
  }
}

.country-list {
  width: 100%;
  background: $white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.react-tel-input .country-list .search {
  background-color: $white;
  margin-bottom: 10px;

  input {
    width: 100%;
    border: 1px solid $borderdarkgrey;
    padding-left: 30px;
    border-radius: 20px;
    height: 40px;
  }
}

.react-tel-input {
  background-color: $white !important;
  max-width: 511px;
  width: 100%;

  @media (max-width: 575px) {
    margin-bottom: 80px;
  }

  .ant-input {
    height: 40px !important;
    background-color: transparent;
    border-radius: 30px !important;
    margin-left: 114px;
    max-width: 75%;
    width: 100%;

    @media (max-width: 767px) {
      margin-left: 110px;
    }

    @media (max-width: 575px) {
      max-width: 136%;
      margin: 0px;
      top: 70px;
    }
  }

  .selected-flag {
    border: 1px solid $bordergrey !important;
    border-radius: 20px !important;
    width: 95px !important;

    &:hover {
      border-color: $baseblue !important;
    }

    @media (max-width: 575px) {
      border: none !important;
      width: 100% !important;
    }

    .flag {
      left: 21px;
    }
  }

  .no-entries-message {
    text-align: center;
  }

  .form-control {
    padding-left: 15px !important;
    border: 1px solid rgba(188, 188, 188, 0.3137254902);

    &:hover {
      border-color: $baseblue;
    }
  }
}

.flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  // max-width: 120px;
  width: 100%;
  cursor: pointer;
  // border: 1px solid $bordergrey;
  border-radius: 30px;
  background: transparent;

  @media (max-width: 575px) {
    border: 1px solid $bordergrey;

    &:hover {
      border-color: $baseblue;
    }
  }

  .country-list {
    position: relative !important;
    width: 100% !important;
    // max-width: 400px;
  }

  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 21px;
    background: url("./Components/Assets/Images/dropicon.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    top: 57%;
    left: 60px !important;
    transform: translateY(-50%);

    @media (max-width: 575px) {
      left: auto !important;
      right: 12px !important;
    }
  }
}

.react-tel-input .flag-dropdown.open {
  // border: 1px solid $bordergrey !important;

  &::after {
    transform: rotate(180deg);
    // top: 23px;
    right: 17px;
    top: 60%;
    transform: translateY(-50%);
  }
}

input[type="text"] {
  padding-left: 24px;
}

.ant-picker-input {
  padding-left: 14px;
}

// order tabs
.buttonTabs {
  .ant-tabs-tab {
    font-weight: 400;
    padding: 9px 28px !important;
  }

  span {
    display: flex;
    gap: 8px;
    align-items: center;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .ant-tabs-tab {
    background-color: $bg-light-gray-F3F3F3;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-tab-active {
    background-color: $baseblue;
    border-radius: 8px 8px 0px 0px;
  }

  .ant-tabs-nav .ant-tabs-nav-list {
    gap: 10px;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    color: $darktext !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    filter: brightness(0) invert(1);
  }
}

// loginPage tabs
.loginTab {
  .ant-tabs-top>.ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  .ant-tabs-tab-btn {
    @include fluid-type(320px, 1920px, 14px, 18px);
  }

  .ant-tabs-tab {
    padding: 10px 40px !important;
  }

  .ant-tabs-nav {
    margin: 15px 0px !important;
  }

  .ant-tabs-top {
    margin-bottom: 10px;
  }
}

.input_righttext {
  .input_imgcontent {
    p {
      color: $baseblue;
      font-weight: 600;
    }
  }
}

.marketTitle {
  @include fluid-type(320px, 1920px, 12px, 12px);
  color: $borderdarkgrey;
  padding-top: 10px;
}

.errorCls {
  color: $red !important;
}

// otp
.otp_clicked {
  color: $base-hover !important;
}

// captcha
.captchaStyle {
  margin: 15px 0px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.positionedContent {
  position: absolute;
  inset: 0;
  z-index: 9;
  background: $white;
  border-radius: 20px;
  overflow-y: auto;
}

// loader
.loader_style {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.66);
  z-index: 999;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: initial !important;
}

.ant-modal-close-x {
  .anticon svg {
    color: $darktext !important;
  }
}

.titleModal {
  text-align: center;
  @include fluid-type(320px, 1920px, 14px, 16px);
  padding-top: 15px;
  font-weight: 600;
  color: $darktext;
}

// .isOdd {
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     inset: 0;
//     border-radius: 20px;
//     padding: 1px;
//     background: linear-gradient(180deg, rgba(247,249,253,1) 50%, rgba(1,76,236,1) 100%);
//     // background: linear-gradient(180deg, #f7f9fd, #014cec);
//     -webkit-mask: linear-gradient(#fff 0 0) content-box,
//       linear-gradient(#fff 0 0);
//     -webkit-mask-composite: xor;
//     mask-composite: exclude;
//   }
// }

// .isEven {
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     inset: 0;
//     border-radius: 20px;
//     padding: 1px;
//     background: linear-gradient(180deg, rgba(1,76,236,1) 0%, rgba(247,249,253,1) 50%);
//     -webkit-mask: linear-gradient(#fff 0 0) content-box,
//       linear-gradient(#fff 0 0);
//     -webkit-mask-composite: xor;
//     mask-composite: exclude;

//     @media (max-width: 991px) {
//       background: none;
//       background-image: linear-gradient(180deg, #f7f9fd, #014cec);
//       -webkit-mask: linear-gradient(#fff 0 0) content-box,
//         linear-gradient(#fff 0 0);
//       -webkit-mask-composite: xor;
//       mask-composite: exclude;
//     }
//   }
// }

.text-center {
  text-align: center;
}

.curserPointer {
  cursor: pointer;
}

.coin_icon {
  width: 35px;
  aspect-ratio: 1;
  box-sizing: content-box !important;

  @media (max-width: 767px) {
    width: 30px;
  }

  @media (max-width: 480px) {
    width: 20px;
  }
}

.activeBtn {
  background-color: #284261 !important;
  color: #fff !important;
}

.border_round {
  .ant-modal-content {
    border-radius: 20px !important;
  }
}

.height50 {
  .customSelect.ant-select {
    height: 40px;
  }

  .mb12 {
    margin-bottom: 12px;
  }
}

/***************pagination*****************/
.pagination {
  padding: 15px;
  text-align: center;

  .rg-pagination {

    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-prev .ant-pagination-item-link {
      border: none;
      color: black;
    }

    .ant-pagination-item-active {
      background-color: $baseblue;
      border-radius: 50%;

      a {
        color: white;
      }

      &:hover {
        color: black;
      }
    }
  }
}

.ant-pagination-item {
  border: none !important;
  background-color: transparent;

  &:hover {
    a {
      color: $white;
    }
  }
}

.input_textimg.customInputWithdarw .input_amount {
  background-color: $bg-light-gray-F3F3F3 !important;
  padding-right: 50px;

  &::placeholder {
    color: $text-80838E;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
  }
}

.otpmodal {
  .ant-modal-body {
    padding: 30px;
  }
}

.empty_data {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px !important;
  color: #80838e;
  @include fluid-type(320px, 1920px, 14px, 16px);
  font-weight: 500;
}

.dltModal {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    color: $darktext;
    @include fluid-type(320px, 1920px, 14px, 20px);
  }

  p {
    color: $borderdarkgrey;
    text-align: center;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }

  .dltBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;

    button {
      border-radius: 30px;
      opacity: 1;
      background-color: #f3f3f3;
      border: 0;
      color: $darktext;
      padding: 0px 40px;
      height: 40px;
      @include fluid-type(320px, 1920px, 14px, 16px);
    }

    .confrBtn {
      color: #cf0038;
    }

    .confirmBtn {
      background-color: $baseblue;
      color: $white;
    }
  }
}

button {
  cursor: pointer;
}

input.ant-input.amountField {
  min-width: 150px;
  max-width: 280px;
  padding: 2px 13px !important;
  border: 1px solid #284261 !important;
  margin: 0 8px;
  height: auto !important;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
}

// Steps css------------------------

.listingSteps {
  &__itemsDetail {
    .ant-steps-item {
      height: 82px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
      background-color: $baseblue !important;
      border: 0;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
      color: $white;
    }

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: $baseblue;
      border: 0;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: $baseblue !important;
    }

    .ant-steps-item-tail::after {
      width: 2px !important;
    }

    .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
      color: $white;
    }

    .ant-steps-item-tail {
      padding: 33px 0 1px !important;
    }

    .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      color: $borderdarkgrey;
      margin-top: 3px;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      margin-top: 3px;
      color: $baseblue;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
      @include fluid-type(320px, 1920px, 14px, 15px);
      padding: 0;
      margin-top: 5px;
    }
  }
}

.ant-progress-bg,
.ant-progress-success-bg {
  background-color: #014cec !important;
}

// Upload Css---------------

.uploadItem {
  .ant-upload-select {
    box-shadow: 0px 0px 12px #00000012;
    border-radius: 20px;
    background-color: $white;
    width: 100%;
    padding: 34px 24px;
  }

  .ant-btn {
    border: 0;
  }
}

.cursorPointer {
  cursor: pointer;
}

.btcList {
  text-align: right;
  padding-bottom: 5px;
  border-bottom: 1px solid $bordergrey;
}

.customMarketDropdown {
  @media (max-width: 767px) {
    position: static !important;
  }

  @media (max-width: 480px) {
    overflow: auto !important;
  }

  .ant-dropdown-menu {
    padding: 0 !important;
    border-radius: 10px;

    &-item {
      padding: 0 !important;
    }
  }
}

.removeRadius {
  border-radius: 0px !important;
}

.alltabspacing {
  padding: 5px 20px 20px 20px !important;
}

.open_AllOrderTab {
  margin: 20px;
  border: 1px solid #e3e3e3;
  border-radius: 20px;

  .ant-tabs-nav {
    border-radius: 20px 20px 0 0px;
  }

  .ant-table-empty {
    border-radius: 0px 0px 20px 20px;
  }

  .ant-table-content {
    table {
      th {
        background-color: #284261 !important;
        font-family: $baseFont3 ;
      }
    }
  }

  .ant-tabs-tab {
    padding: 12px 29px !important;

    @media (max-width: 480px) {
      padding: 12px !important;
    }
  }

  .ant-tabs-tab-btn {
    p {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-family: $baseFont3 ;
    }
  }
}

#tv_chart_container {
  padding-left: 21px;

  @media (max-width: 767px) {
    padding-left: 0;
  }
}

.passPadding {
  input {
    padding-right: 110px;
  }
}

.logo-blue {
  display: inline-block;
}

// .react-tel-input .selected-flag{
//   border: 1px solid $bordergrey !important;
//   border-radius: 20px !important;
//   width: 109px !important;
// }

.walletdropexchange {
  .ant-dropdown {
    .ant-dropdown-menu {
      box-shadow: none !important;
      background-color: transparent;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  .ant-dropdown-menu-item:hover,
  :active,
  :focus,
  :focus-visible,
  :focus-within {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.noRecordFound {
  text-align: center;
  width: 100%;

  p {
    color: rgba(165, 163, 163, 0.85) !important;
  }
}

.nointernet {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    width: 200px;
    margin-bottom: 50px;
  }

  .textDiv {
    text-align: center;

    h4 {
      margin: 0px;
    }
  }
}

.mt10 {
  margin-top: 10px;
}

.stackMinFont {
  font-size: 12px !important;
  margin-top: 5px;
}

// button loading set on position

button.ant-btn {
  .ant-btn-loading-icon {
    display: none;
    position: absolute;
    z-index: 99;
    background: transparent;
    left: 0;
    right: 0;
    opacity: 0.8;
    top: 0px;
    bottom: 0;

    span {
      height: 100%;

      svg {
        display: flex;
        align-content: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

.assetInner {
  .dataAssets {
    // width: 83px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-bottom: -2px;
    margin-right: 6px;
    color: $tabletext;
    font-weight: 600;
  }

  .dataassetSpan {
    color: $text-80838E !important;
    font-weight: 500;
  }
}

.btnsmall1 {
  color: white;
  width: fit-content;
  height: 30px;
  padding: 4px;
  min-width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnsmall2 {
  margin-left: 4px;
}

.moversList {
  max-height: 315px;
  overflow: auto;
  margin-bottom: 15px;
  scrollbar-width: none;

  @media (max-width: 767px) {
    height: 100%;
  }
}

.cursorAuto {
  cursor: auto;
}

.ant-table-thead {
  cursor: auto;
}

.mrt30 {
  margin-top: 30px;
}

.customMarketDropdown.ant-dropdown {
  width: 520px !important;
}

@media (max-width: 767px) {
  .customMarketDropdown.ant-dropdown {
    width: 100% !important;
  }
}

//Responsive Layout//
@media (max-width: 767px) {
  .responsiveLayout {
    .logo {
      display: none;
    }

    .ant-layout {
      flex-direction: column !important;
      height: calc(100vh - 0px);

      &-content {
        width: 100% !important;
      }

      &-sider {
        flex: 1 !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        background: #fff;
        padding-top: 5px;
        padding-bottom: 5px;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9;
        border-top: 1px solid rgba(0, 0, 0, 0.0705882353) !important;

        .ant-menu {
          flex-direction: row !important;
          justify-content: space-between !important;

          &-item {
            border-radius: 30px 30px 0 0 !important;
            border-bottom: 0 !important;
            border-left: 0 !important;
            width: 60px;

            &-active,
            &-selected,
            &:hover {
              border-left: 0 !important;
              background-color: transparent !important;
            }
          }

          &-title-content {
            display: none !important;
          }
        }
      }
    }
  }
}

.ant-table {
  tbody tr.ant-table-placeholder {
    td.ant-table-cell {
      display: revert !important;
    }
  }
}

// .style_settingPage__TLWyB {
//   .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
//     display: none;
//   }
// }

.filer {
  img {
    height: 20px;
    cursor: pointer;
  }
}

.filter_tabs {
  display: flex;
  gap: 20px;
  align-items: center;
  z-index: 9;

  @media (max-width: 1199px) {
    top: -50px;
    right: 24px;
  }

  @media (max-width: 585px) {
    top: -27px;
  }

  p {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;

    &:hover,
    &.active {
      color: #284261;
    }
  }

  svg {
    height: 22px;
    width: 22px;
    cursor: pointer;

    &:hover,
    &.active {
      fill: #284261;
    }
  }
}

.filtrspacing {
  padding: 20px;
}

.centerbutns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 30px;

  @media (max-width:991px) {
    gap: 12px;
  }
}

.filtrspacing {
  background: transparent linear-gradient(180deg, #e2fffd 0%, #fbfcff 100%) 0% 0% no-repeat padding-box;

  label {
    white-space: nowrap;
  }
}

.labelto {
  color: #80838e;
}

.wd20 {
  width: 26% !important;
}

.wd60 {
  width: 48% !important;
}

.swapPage {
  .ant-tabs-content {
    position: inherit;
  }
}

.anticon.anticon-delete {
  color: grey;
  font-size: 20px;
  font-weight: 600;
  background: transparent;
  padding: 10px;
  border-radius: 100%;
  transition: 0.3s all;
  cursor: pointer;

  &:hover {
    color: #cf304a;
    // color: $white;
  }
}

.responsive {
  @media (max-width: 767px) {
    .responsiveLayout .ant-layout {
      height: 100%;
    }
  }
}

.overflowText {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.convertHisTable {
  .ant-table {
    // max-height: 450px;
    max-height: calc(100vh - 425px);
  }

  .ant-table-header {
    overflow: visible !important;
  }

  .ant-table-body {
    overflow: visible !important;

    table {
      table-layout: auto !important;
    }
  }

  .ant-table-container {

    &::after,
    &::before {
      content: none !important;
    }
  }
}

.height450Table {
  .ant-table {
    // max-height: 495px;
    max-height: calc(100vh - 425px);
  }
}

.timeBtn {
  font-weight: 500;

  &:hover {
    color: #014cec !important;
  }
}

.timeActive {
  color: #014cec !important;
}

.pdFromLeft input {
  padding-left: 30px !important;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexGap10 {
  gap: 10px;
}

.orderCls {
  color: white;
  width: fit-content;
  min-width: 40px;
  height: 30px;
  padding: 4px;
  border-radius: 10px;
}

.maintop_swap {
  background-color: #f3f3f3;
  border-radius: 8px;
  margin: 20px 0px;
  padding: 10px 10px;
  position: relative;

  .swapicon {
    text-align: right;
    display: block;
    margin: 0 0 auto auto;
    cursor: pointer;
    margin-right: 20px;
    position: absolute;
    right: 0;
    top: 55px;
    height: 27px;
    width: 27px;
  }

  .swapcurrent {
    height: 23px;
    width: 23px;
  }

  .rotate_iconTransfer {
    transform: rotate(30deg);
  }

  .transfer_swap {
    display: flex;
    gap: 50px;
    margin: 10px 0px;
    align-items: center;

    .settheme_span {
      @include fluid-type(320px, 1920px, 14px, 16px);
      color: #80838e !important;
    }

    >div {
      display: flex;
      gap: 15px;
      max-width: 120px;
      width: 100%;
      padding: 10px;

      span {
        @include fluid-type(320px, 1920px, 14px, 16px);

        color: #80838e !important;
      }

      p {
        margin: 0px;
        color: #80838e;
      }
    }

    h6 {
      @include fluid-type(320px, 1920px, 14px, 16px);

      color: #80838e;
    }
  }
}

.border_radisremove {
  .card {
    border-radius: 8px;
  }
}

.sendTradeBtn {
  span.leftIcon {
    svg {
      path {
        stroke: #284261;
      }
    }
  }
}

:where(.css-1m62vyb).ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25);
  text-transform: capitalize;
}

.coinlistupload {
  display: inline-block;
  word-break: break-all;
  margin-right: 4px;

  @media (max-width: 480px) {
    margin-right: 12px;
  }
}

.text {
  word-break: break-word;
}

.grayColor {
  color: #80838e;
}

.submitapplication {
  margin-right: 30px;

  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.settingtabs {
  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        padding: 30px !important;

        &-active {
          border-bottom: 2px solid #284261;
        }
      }
    }
  }
}

// Existing styles remain unchanged
// Add styles for enabling scrolling on wider screens
.scroll-enabled {
  @media screen and (min-width: 728px) {
    overflow-x: scroll;
  }
}

// Add styles for disabling scrolling on smaller screens
.scroll-disabled {
  @media screen and (max-width: 727px) {
    overflow-x: hidden;
  }
}

.centerCaptcha {
  text-align: center;
  margin: 15px;

  >iframe {
    width: 100% !important;
    max-width: 300px;
  }
}

.marginTB {
  margin: 24px 0px;
}

.text_size {

  // margin-bottom: 10px;
  .label {
    font-size: 14px;
  }
}

.export_modal {
  .ant-modal-content {
    padding: 25px;
  }

  .centerbutns {
    margin-top: 20px;
    margin-bottom: 10px;

    button {
      width: 50%;
    }
  }

  .titleModal {
    margin-bottom: 30px !important;
  }
}

.walletviewmain {
  display: flex;
  justify-content: space-between;

  .walletView {
    display: flex;
    gap: 10px;

    &__buttons {
      color: $borderdarkgrey;
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-weight: 500;
      border: 0;
      transition: 0.3s all;
      cursor: pointer;
      padding: 10px 24px;
      background-color: transparent;

      &__active,
      &:hover {
        // background-color: $CommonButton;
        border-color: $baseblue;
        border-bottom: 1px solid;
        color: $baseblue !important;
      }
    }
  }

  .convertMng {
    color: #284261;
    font-size: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
  }
}

.convertdata {
  background-color: #f3f3f3;
  padding: 10px 30px;
  margin: 20px 30px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  color: #03a89f;
  font-size: 16px;
  cursor: pointer;
}

.convertAssets {
  .multiselect {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
  }
}

.dollarValue {
  color: grey !important;
  margin-left: 8px;
  font-size: 12px;
}

.ant-dropdown {
  z-index: 1 !important;
}

.submissionModal {
  h2 {
    @include fluid-type(320px, 1920px, 16px, 20px);
    color: #000000;
    text-align: center;
    font-weight: 600;
  }

  p {
    @include fluid-type(320px, 1920px, 12px, 14px);
    color: #80838e;
    text-align: center;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 25px;
  }

  .submisionBtn {
    width: 100%;
    max-width: 185px;
  }
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.grey-bg-round {
  background-color: #f3f3f3;
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  vertical-align: middle;
  margin-left: 8px;

  svg {
    max-width: 13px;
    height: 100%;
    transform: translate(-50%);
    left: 50%;
    position: absolute;
  }
}

.hiddenarrowIcon {
  .ant-select-arrow {
    svg {
      display: none !important;
    }
  }
}

.custom_Select {
  .selectlabel {
    padding-bottom: 8px;
    display: block;
  }

  display: inline;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border-radius: 16px;
    border-color: #d2d2d2;
    color: #80838e;
    border-radius: 30px;
    font-size: 14px;
  }

  .ant-select-selector {
    padding: 9px !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #284261;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #284261 !important;
    box-shadow: none !important;
  }
}

// ---------------status color---------------------
.red {
  color: #cf304a;
}

.green {
  color: #1daa68;
}

.fundingBalance {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .fundingBalance-block {
    flex: 0 1 33.3%;
    padding-bottom: 40px;

    @media (max-width: 991px) {
      margin-right: 20px;
      white-space: nowrap;
    }

    &:nth-child(3) {
      .widthBlock {
        margin-left: auto;

        @media (max-width: 991px) {
          margin-left: inherit;
        }
      }
    }

    &:nth-child(2),
    &:nth-child(5) {
      .widthBlock {
        margin: auto;
      }
    }

    .widthBlock {
      max-width: 300px;
    }

    .till_date {
      @include fluid-type(320px, 1920px, 12px, 14px);
      color: #80838e;
      margin-left: 5px;

      @media (max-width: 991px) {
        display: block;
      }
    }
  }
}

.comingSoon {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include fluid-type(320px, 1920px, 16px, 24px);
}

.stakingModal {
  .kycModal {
    padding: 0px !important;
  }
}

.buySellModal {
  .ant-modal-close {
    top: -5px !important;
    right: -5px !important;
  }
}

.settingSebhd {
  font-size: 17px;
  font-weight: 800;
  padding: 20px;
}

.activityDiv,
.tabledevicemanage {
  padding: 30px 40px 30px 50px;

  @media (max-width: 1366px) {
    padding: 20px;
  }

  .headingmanage {
    padding: 20px 40px;

    @media (max-width: 991px) {
      padding: 15px 20px;
    }
  }

  .topHeading {

    h4,
    p {
      padding: 0 0 10px 0;
    }
  }
}

.topHeading p {
  padding: 0px 40px 20px 40px;
  color: #000000;
}

.topHeading .text-black-20 {
  padding-bottom: 10px;
}

.deleteModal .ant-modal-content {
  border-radius: 20px;
  text-align: center;

  .ant-modal-footer {
    padding: 20px 16px;
    text-align: center;
  }
}

.modalTab {
  display: flex;
  gap: 16px;

  .modalTabsBtn {
    color: $text-80838E;
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 500;
    transition: 0.3s all;
    cursor: pointer;
    padding: 10px 24px;
    background: #ffffff00 0% 0% no-repeat padding-box;
    border: 1px solid;
    border-radius: 30px;
    border-color: #bcbcbc;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 26px 0;

    img {
      filter: grayscale(100%);
    }

    &:hover {
      // background-color: $CommonButton;
      border-color: $baseblue;
      color: $baseblue !important;

      img {
        filter: none;
      }
    }
  }

  .active {
    color: $baseblue !important;
    border-color: $baseblue;

    img {
      filter: none;
    }
  }
}

.uploadCmnSec {
  max-width: 485px;
  width: 100%;

  .ant-upload.ant-upload-drag {
    background: #ffffff !important;
    border: 1px dashed #bcbcbc !important;
    border-radius: 30px !important;
    opacity: 1;
    // max-width: 485px;
    // width: 100%;
    height: 211px;
  }

  .ant-upload {
    .ant-upload-drag-container {
      background-image: url("./Components/Assets/Images/bgUpload.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    #launcher {
      width: 100% !important;
      bottom: -9px !important;
      border: 0px;
      left: -58px !important;

      @media (max-width: 475px) {
        left: -30px !important;
      }
    }
  }

  label {
    color: $darktext;
    @include fluid-type(320px, 1920px, 14px, 16px);
    padding-bottom: 8px;
    display: flex;
  }
}

.notificationCollaps {
  .anticon.anticon-delete {
    color: $grey-707070;
    display: flex;
    background: transparent;
    padding: 4px;
    font-size: 12px !important;

    &:hover {
      color: #cf304a !important;
    }
  }

  .anticon.anticon-read {
    color: $grey-707070;

    &:hover {
      color: $baseblue;
    }
  }
}

.notiColaps {
  .ant-collapse-header {
    padding: 10px 15px !important;
  }

  .ant-collapse {
    border: none;
  }

  .ant-collapse-item {
    margin-bottom: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px !important;
  }

  .unReadClass {
    .notificationCollaps {
      h5 {
        color: $darktext;
      }
    }
  }
}

#launcher,
#webWidget {
  right: 0;
  left: auto !important;
  margin: 4px 20px !important;

  @media (max-width: 767px) {
    bottom: 60px !important;
  }
}

@media (max-width: 767px) {
  .footer__text {
    text-align: left;
    margin-left: 10px;
  }
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.cmnHeading_h2 {
  @include fluid-type(320px, 1920px, 14px, 18px);
  font-weight: 600;
  color: #000000;
}

.mrbtm {
  margin-bottom: 10px !important;
}

.captcaBtnGrup {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.captchaModalHeader {
  margin-top: -15px;
  margin-bottom: 20px;

  h5 {
    text-align: center;
    font-weight: 600;
    @include fluid-type(320px, 1920px, 14px, 16px);
  }
}

.ant-btn.btn_custom[title="Log In"] {
  border: 1px solid $baseblue;
  transition: all 0.3s linear;

  &:hover {
    background-color: transparent !important;
    color: #fff !important;
  }
}

.ant-tabs-extra-content {
  margin-right: 20px;

  h4 {
    font-family: $baseFont3 ;
  }
}

.delButton {
  padding-left: 0 !important;
}

.modal-open {
  overflow: hidden;
}

.staticLabel {
  padding-bottom: 8px;
  display: block;
}

.stateRgNo {
  margin: 0 -10px;
  // flex-wrap: wrap;

  >div {
    padding: 0 10px;
    min-width: 100px;
  }
}

.futureModal {
  .ant-modal-content {
    padding: 32px 28px;
    width: 479px;

    h1 {
      @include fluid-type(320px, 1920px, 16px, 24px);

      font-weight: 600;
    }

    .crosBtn {
      display: flex;
      gap: 20px;
      margin-top: 24px;

      button {
        width: 100%;
      }
    }

    p {
      color: #000;
      font-size: 14px;
    }

    .futureDiv {
      padding-bottom: 16px;
      border-bottom: 1px solid #e3e3e3;
      padding-top: 20px;
    }

    .innerSec {
      padding-top: 16px;
    }

    .redDiv {
      display: flex;
      align-items: baseline;
      padding-top: 20px;
      gap: 8px;

      p {
        color: #cf304a;
      }
    }

    .btn_custom {
      margin-top: 50px;
      width: 100%;
    }

    .leverageList {
      padding-left: 15px;
      padding-top: 24px;

      li {
        color: #000;
        font-size: 14px;
      }
    }
  }

  .modalHeading {
    font-size: 16px !important;
    font-weight: 500;
    padding-top: 20px;
  }

  .rangePicker {
    background: #f3f3f3;
    border-radius: 30px;
    opacity: 1;
    padding: 6px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 8px;

    span {
      font-size: 18px;
      font-weight: 500;

      &.counter {
        padding: 0px 6px;
        font-size: 30px;
        cursor: pointer;

        &:hover {
          color: $baseblue;
        }
      }
    }
  }
}

.fund-Draw {
  div.ant-drawer-content-wrapper {
    width: 534px;

    @media (max-width: 767px) {
      max-width: 350px;
    }

    @media (max-width: 479px) {
      max-width: 300px;
    }
  }

  .ant-drawer-header-title {
    div.ant-drawer-title {
      @include fluid-type(320px, 1920px, 16px, 24px);
      font-weight: 600;
    }

    button.ant-drawer-close {
      position: absolute;
      right: 10px;
    }
  }

  div.ant-drawer-wrapper-body {
    background-color: #284261;

    .ant-drawer-header {
      padding: 50px 35px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }

    .ant-drawer-body {
      padding: 0 35px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }

  // &.ant-drawer-open {
  //   background-color: #284261;
  // }
  .deposite-link-draw {
    li {
      list-style: none;
      margin-bottom: 50px;

      h6 {
        @include fluid-type(320px, 1920px, 12px, 14px);
        font-weight: 700;
        padding-bottom: 12px; // margin-bottom: 12px;
      }

      h5 {
        @include fluid-type(320px, 1920px, 16px, 18px);
        font-weight: 700;

        span {
          @include fluid-type(320px, 1920px, 12px, 14px);
          font-weight: 400;
          max-width: 290px;
          display: inline-block;
          color: #80838e;
        }
      }
    }
  }
}

.comman-padding {
  padding: 24px 40px;

  @media (max-width: 767px) {
    padding: 15px;
  }
}

.bank-deposite-draw-tabs {
  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      flex-basis: 50%;
      margin: auto;

      .ant-tabs-tab-btn {
        width: 100%;
        text-align: center;
      }
    }
  }

  .open_AllOrderTab {
    margin: 0;
  }

  .normalTabs {
    .ant-tabs-nav-list {
      width: unset;

      .ant-tabs-tab {
        flex-basis: unset;
        margin: auto;

        .ant-tabs-tab-btn {
          width: unset;
          text-align: center;
        }
      }
    }
  }
}

.ant-form-item {
  .ant-row.ant-form-item-row {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .ant-col.ant-form-item-control {
      width: 100%;
    }
  }

  label {
    &::after {
      display: none;
    }
  }
}

.Deposite-link {
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.withdrawPage {
  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .btnCustom {
    margin-top: 20px;
  }
}

.withdrawTab1,
.withdrawTab2 {
  .ant-tabs-nav-list {
    width: auto;
  }

  .ant-tabs-nav {
    margin-bottom: 10px !important;
  }

  p {
    position: static !important;
  }
}

.depositeWithdraw {
  max-width: 100% !important;
  width: 100% !important;

  .leftIcon img {
    max-height: 18px;
  }
}

.languageDrop {
  top: 78px !important;
  z-index: 9999 !important;
}

.selectedLanguage {
  background-color: #f5f5f5;
}

.languageMenu {
  margin-bottom: 0 !important;

  ul {
    background-color: #fff;
    padding: 0;
    padding-bottom: 0px;
    list-style: none;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1607843137);
    padding-bottom: 10px;
    border-radius: 10px;

    li {
      padding: 7px 20px;
      cursor: pointer;
      width: 150px;
      cursor: pointer;
    }
  }
}

div.ant-statistic {

  &-title,
  &-content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: $baseblue;
  }
}

textarea.ant-input {
  border-radius: 25px;
  resize: none;
  font-family: $baseFont3;
  border: 1px solid $border-dcd5d5;
  padding-top: 19px;
  margin-top: 10px;

  &:hover {
    border: 1px solid $baseblue;
  }

  &:hover {
    border: 1px solid $baseblue;
  }

  &::placeholder {
    color: $plateformtext;
    font-weight: 500;
  }
}

button.backbtn {
  margin-bottom: 10px;
  font-family: $baseFont3;
  color: $baseblue;
  @include fluid-type(320px, 1920px, 16px, 18px);
  font-weight: 600;
  padding-left: 0;

  span {
    color: $baseblue;
    margin-left: 5px;
  }

  &:hover {
    color: $baseblue;
    opacity: 0.7;
  }
}

.launchpadHis {
  min-height: calc(100vh - 140px);
  height: 100%;
  padding: 60px 0;
  background-image: url("./Components/Assets/Images/formbg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .launchpadTable {
    background-color: $white;
    border: 1px solid $border-dcd5d5;
    border-top: 0;
    border-radius: 24px;

    .ant-table {
      border-radius: 24px;
    }

    .ant-table-thead tr>th {
      background-color: $bgtablehead;
      color: $plateformtext;
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 500;
      font-family: $baseFont3;
    }

    .ant-table-tbody tr>td {
      color: $darktext;
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-weight: 400;
      font-family: $baseFont3;
      border-bottom: 1px solid $tableborder !important;

      // &:last-child {}
    }
  }
}

div.ant-card.LaunchpadCard_custom {
  border-radius: 24px;
  box-sizing: border-box;

  padding: 36px 24px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  @media (max-width: 767px) {
    padding: 15px;
  }

  h5.ant-typography {
    color: #0b3d4c;
    font-weight: 700;
    font-size: 20px;
    white-space: nowrap;
  }
}

.customStep {
  * {

    .ant-steps-icon,
    .ant-steps-item-process .ant-steps-item-icon {
      border-color: #284261;
    }

    svg {
      fill: #284261;
    }

    .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: #284261;
    }

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
      background-color: #284261;
    }
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #284261;
  }

  .ant-steps {
    .ant-steps-item-title {
      @include fluid-type(320px, 1920px, 16px, 18px);
      font-style: normal;
      font-weight: 500;
      /* 155.556% */
      letter-spacing: 0.54px;
    }
  }

  .ant-steps-item-description {
    max-width: 675px;

    h6 {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-style: normal;
      font-weight: 500;
      color: #616161;
      padding: 18px 0;
      /* 175% */
      letter-spacing: 0.48px;
    }

    p {
      @include fluid-type(320px, 1920px, 12px, 14px);
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: 0.42px;
      padding-bottom: 28px;
    }
  }
}

.LaunchpadDetail-page {
  h3.ant-typography {
    @include fluid-type(320px, 1920px, 20px, 28px);
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    /* 142.857% */
    letter-spacing: 0.84px;
    padding-bottom: 50px;
  }
}

span.customTag.ant-tag {
  border-top-left-radius: 14px;
  border-bottom-right-radius: 15px;
  padding: 5px 18px;
}

.commanBgFull {
  padding: 60px 0;
  background-image: url("./Components/Assets/Images/bglistingplateform.png");
  background-size: 100% 100%;
  background-position: top left;
  background-repeat: no-repeat;
}

.ant-breadcrumb {
  padding-bottom: 30px !important;

  @media (max-width: 575px) {
    padding-bottom: 15px !important;
  }

  ol {
    li {
      a {
        color: $plateformhead;
        font-weight: 500;
      }
    }

    li:last-child a {
      color: $baseblue;
      font-weight: 600;
    }
  }

  .ant-breadcrumb-separator {
    color: $bgseparator;
  }
}

.ant-modal-wrap {
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.LaunchpadCard {
  display: flex;
  width: 100%;
  gap: 25px;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .block {
    flex: 1;

    .title {
      @include fluid-type(320px, 1920px, 14px, 16px);
      font-style: normal;
      line-height: normal;
      font-weight: 400;
      color: #616161;
    }

    &.imageblock {
      max-width: 220px;

      @media (max-width: 767px) {
        max-width: 100%;
      }

      .iconOuter {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        min-height: 180px;

        img {
          width: 95px;
        }
      }
    }

    .Durationblock {
      align-items: flex-end;

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    // padding: 16px;
  }
}

//iframe social responsibility
.marketiframe {
  iframe {
    height: 521px;
    width: 100%;
    border-radius: 20px;

    @media (max-width: 991px) {
      height: 450px;
    }

    @media (max-width: 575px) {
      height: 350px;
    }
  }
}

// ================newCard================
div.ant-card.NewsCard-custom {
  // max-width: 326px;

  border-radius: 24px;
  border-radius: 16px;
  box-shadow: 0px 0px 12px #0000001a;
}

.cardSecblockInner {
  height: 100vh;
}

button.ant-btn.playSore-Btn {
  height: unset;
  display: flex;
  border-radius: 30px;
  padding: 5px 20px;
  max-width: 193px;
  width: 100%;
  background-color: white;
  border: 1px solid white;
  // line-height: unset;

  span {
    text-align: left;
    margin-left: 15px;
    @include fluid-type(320px, 1920px, 12px, 14px);

    strong {
      @include fluid-type(320px, 1920px, 14px, 18px);
    }
  }

  &:hover {
    background-color: transparent;
    color: white;
  }
}

.langaugeDrop {
  background-color: transparent !important;

  border-radius: 10px;

  .ant-avatar-string {
    position: static !important;
  }
}

// body.mn  {
//   font-family: "roboto", sans-serif;
// }
// body.en {
//   font-family: "Open Sans", sans-serif;
// }

////language conversion style
.mn {
  .commonbtn {
    font-size: 12px !important;

    @media (max-width: 1366px) {
      font-size: 14px !important;
    }

    @media (max-width: 991px) {
      font-size: 11px !important;
    }

    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }

  .headerbtns {
    .ant-btn.btn_custom {
      padding: 10px 15px !important;
    }
  }

  .headertop {
    .ant-btn {
      @media (max-width: 420px) {
        // padding: 5px 0px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }

    button {
      // font-size: 13px !important;
    }

    span {
      // width: unset !important;
    }
  }
}

.btn2FaEnable {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ant-btn-link {
  color: $baseblue !important;

  &:hover {
    opacity: 0.7;
  }
}

.cancelAll {
  border: 1px solid $baseblue !important;
  color: $baseblue !important;

  &:hover {
    opacity: 0.7;
  }
}

.bankAccountSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .anticon-delete {
    position: absolute;
    right: 20px;
  }

  .nubaccount {
    padding-right: 100px;
  }
}

.copyIcon {
  margin-left: 5px;
  position: relative;
  bottom: 2px;
}

.userAgreement {
  height: 85vh;
  overflow: scroll;
  width: 100%;
  padding-bottom: 70px;
}

.contractModal {
  width: 100%;
  position: relative;

  .ant-modal-content {
    max-width: 1340px;
  }

  .btmBtnSec {
    display: flex;
    justify-content: center;
    width: 100%;

    .scroll-to-bottom-button {
      // position: fixed;
      padding: 8px 30px;
      border: none;
      border-radius: 20px;
      background-color: #284261;
      z-index: 9;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      // bottom: 60px;
      // left: 50%;
      // transform: translate(-50%, 50%);
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
        rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
        rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
        rgba(0, 0, 0, 0.07) 0px 32px 64px !important;

      &:hover {
        background-color: #284261 !important;
      }

      &[disabled=""] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.CheckboxText {
  text-align: left;
  font-weight: 600;
  margin: 20px 0;
  padding: 30px 80px;

  p {
    color: #1d3238e4;
    font-size: 18px !important;
  }
}

.ant-select {

  #remove_account,
  .remove_account {
    // display: none;
  }
}

.demobtn {
  @media (max-width: 767px) {
    display: none;
  }
}

.headertop {
  .ant-btn.btn_custom {
    @media (max-width: 420px) {
      // padding: 5px 0px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

.remove_account {
  padding-right: 50px;

  .error-msg-modal {
    color: red;
  }
}

.modlcust {
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .modalwith {
    img {
      width: 70px;
      height: 70px;
      display: block;
      margin: auto;
      margin-bottom: 20px;
    }

    &_innrwith {
      h3 {
        text-align: center;
        margin-bottom: 10px !important;
      }

      .spacetop {
        margin-top: 20px !important;
      }

      .mb-20 {
        margin-bottom: 20px !important;
      }

      .cent_text {
        text-align: center;
        display: block;
      }

      span {
        text-align: center;
      }

      .ullist {
        margin-top: 10px;

        ul {
          list-style: none;
          background-color: #eeeaea;
          padding: 15px;
          border-radius: 10px;

          li {
            display: flex;
            gap: 20px;

            p {
              margin-bottom: 5px;

              &:last-child {
                // width: 360px;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                word-break: break-all;
              }
            }
          }
        }
      }

      .bullets {
        padding-left: 15px;
      }

      .widthconfirm {
        text-align: center;
      }

      button {
        border-radius: 10px;
        background-color: #284261;
        color: #fff;
        height: 45px;
        border: 1px solid grey;
        width: 48%;
        margin-top: 30px;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .modalcnf {
    border-radius: 10px;

    &_innr {
      padding: 10px;

      img {
        display: block;
        margin: auto;
        margin-bottom: 30px;
        transform: rotate(180deg);
      }

      p {
        @include fluid-type(320px, 1920px, 12px, 16px);
        text-align: center;
      }

      >div {
        display: flex;
        gap: 10px;

        button {
          border-radius: 10px;
          background-color: #284261;
          color: #fff;
          height: 45px;
          border: 0px;
          width: 48%;
          margin-top: 30px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}

.orderBookTab {

  // background-color: red;
  .ant-tabs-nav {
    position: absolute !important;
    top: 11px;
    left: 37%;

    &::before {
      display: none;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    gap: 12px;

    .ant-tabs-tab {
      opacity: 0.5;
    }

    .ant-tabs-tab-active {
      opacity: initial;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    padding: 0 !important;
  }

  // .ant-tabs-content-holder {
  //   .ant-tabs-tabpane-active {
  //     height: 507px;
  //   }
  // }
  .asks {
    .askHeight {
      height: 486px;
      overflow-y: auto;
    }
  }
}

.priceTable {
  .ant-table-content {
    height: 100% !important;
    overflow-y: auto !important;
    max-height: 500px;
  }
}

.networkErr {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}

.networkErr p {
  width: 50%;
}

.selectDeletePopup {
  border-radius: 10px !important;
  overflow: hidden !important;
  padding-bottom: 0 !important;
  margin-bottom: 20px;

  .ant-modal-title {
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
  }

  .ant-input.text-Area {
    border-radius: 10px !important;
    margin-bottom: 20px;
    margin-top: 0 !important;
  }

  label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px !important;
    display: block;
  }

  .ant-modal-footer {
    padding: 20px;
  }

  .ant-btn.ant-btn-primary {
    background-color: #284261;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 9px 20px;
    min-width: 120px;
    height: 40px;
    cursor: pointer;
    margin-left: 10px;
    border: 1px solid transparent;
    transition: all 0.3s linear;

    &:hover {
      background-color: transparent;
      border-color: #284261;
      color: #284261;
    }
  }

  .ant-btn.ant-btn-default {
    background-color: transparent;
    color: #284261;
    border: none;
    border-radius: 30px;
    padding: 9px 20px;
    min-width: 120px;
    height: 40px;
    cursor: pointer;
    margin-left: 10px;
    transition: all 0.3s linear;
    border: 1px solid #284261;

    &:hover {
      background-color: #284261;
      border-color: transparent;
      color: #fff;
    }
  }
}

.sellViewModal {
  .ant-modal-content {
    overflow: hidden;
  }
}

.viewmodal {
  padding: 20px;

  &_paragraph {
    color: $borderdarkgrey;
    @include fluid-type(320px, 1920px, 14px, 16px);
    font-weight: 600;
  }

  ul {
    list-style: none;
    text-decoration: none;
    padding: 0;
    display: flex;
    justify-content: space-between;

    li {
      margin-bottom: 0 !important;
    }
  }

  .modalAmnFld {
    margin-bottom: 10px;
  }

  .qrImg {
    margin-top: 20px;
  }

  button {
    text-align: center;
    font-weight: 500;
    display: block !important;
    margin: auto;
    margin-top: 50px;
  }

  svg {
    display: block;
    margin: auto;
  }
}

.customMarketDropdown {
  position: relative;
}

.kycHeadingTExt {
  text-align: center;
}

.divider {
  display: none !important;
}

.menu {
  width: 40px;
  height: 50px;
  margin-left: 10px;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
  }
}

.menu-1 {
  position: absolute;
  top: 0%;
  left: 0.5%;

  .menu-item {
    cursor: pointer;
    background: #fff;
    height: 4px;
    margin: 5px;
  }
}

.ant_drawer_btn {
  .ant-drawer-body {
    @include breakpoint(small) {
      padding: 0;
    }

    .inner-drawer {
      display: flex;
      gap: 20px;
      flex-direction: column;

      .ant-layout-sider {
        @include breakpoint(small) {
          width: 100% !important;
          max-width: 100% !important;
          min-height: calc(100vh - 55px);
          padding: 29px 0 0 0;
        }
      }
      .sideBar{
        display: inline-block !important;
      }
    }

    button {
      max-width: 150px;
      width: 100%;
    }
  }

  .ant-drawer-wrapper-body {
    background-color: transparent;
  }

  .anticon-close {
    svg {
      color: #000;
    }
  }
}

.drawer_header {
  display: none;

  @media (max-width: 875px) {
    display: block;
  }
}

.header_btns {
  @media (max-width: 875px) {
    display: none;
  }
}

.noScroll {
  height: calc(100vh - 460px);

  @media screen and (max-width: 991px) {
    height: 350px;
  }
}

.orderTableResScroolIssues {
  .ant-table-content {
    height: 304px;
    min-height: 200px;
    overflow: auto;
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
  }
}

.marketCompSec {
  display: flex;
  align-items: center;

  >span {
    display: inline-flex;
    flex: 0 0 30px;
    max-width: 30px;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    border-radius: 10px;

    &.favorites--active {
      background-color: #284261;

      svg path {
        fill: #fff !important;
      }
    }
  }

  .marketcomp {
    flex: 0 0 100%;
    max-width: calc(100% - 50px);
  }
}

.tabsDrawer .ant-tabs-tab-active p svg path {
  fill: #fff !important;
}

section {
  &.ant-layout-has-sider {
    &.site-layout {
      @include breakpoint(landscape) {
        flex-wrap: wrap;
      }

      >div {
        @include breakpoint(landscape) {
          width: 100%;
          flex: 0 0 100% !important;
        }
      }
    }
  }
}

section {
  section.ant-layout-has-sider {
    .ant-layout-content {
      @include breakpoint(landscape) {
        width: 100% !important;
        flex: 0 0 100% !important;
        min-height: calc(100vh - 139px) !important;
      }
    }
  }
}

.ant-dropdown {
  &.languageDrop {
    .setting {
      ul {
        &.ant-dropdown-menu {
          .ant-dropdown-menu-item {
            span {
              font-family: $baseFont3 !important;
              font-size: 15px;
              font-weight: 500;

              a {
                font-family: $baseFont3 !important;
                font-size: 15px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}

.ant-table .ant-table-container {
  .ant-table-thead {
    th {
      font-size: 18px;
    }
  }

  .ant-table-tbody {
    td {
      font-size: 18px;
    }
  }
}


.pageMain {
  .ant-btn {
    min-width: 161px;
    padding: 0 10px !important;
    font-size: 19px;
    min-height: 47px;
    line-height: 47px;
    border-radius: 50px !important;
    &.agent_btn {
      border: 1px solid transparent !important;
      &:hover {
        background-color: transparent !important;
        border-color: #4CAF50 !important;
        span {
          color: #4CAF50 !important;
        }
      }
    }
    @include breakpoint(landscape){
      min-width:126px;
      font-size: 15px;
      min-height: 43px;
      line-height: 43px;
    }
  }
}

.ant-table-pagination {
  .ant-pagination {
    margin: 0 16px;
    padding-right: 20px;
  }
}

.upgrade-btn {
  min-width: 150px;
  min-height: 39px;
  line-height: 39px;
}

.ant-btn {
  &.contact-button {
    border-color: #B9A143;

    &:hover {
      background-color: transparent !important;
      border-color: #B9A143 !important;
      color: #B9A143 !important;
    }
  }
}

.ant-table-tbody{
  tr{
    td{
      @include breakpoint(small){
        padding: 8px !important;
      }
    }
  }
}